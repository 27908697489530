export const formatoBrasileiro = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export const ValorProduto = (produto) => {
  let ValorComDesconto = produto.produto.promo_valor;
  let percentual =
    parseFloat(produto.produto.promo_valor) -
    parseFloat(produto.produto.preco_item);
  percentual =
    (parseFloat(percentual) / parseFloat(produto.produto.promo_valor)) * 100;
  percentual = Math.round(percentual) + "%";

  return (
    <div>
      <h4>
        {ValorComDesconto > 0 ? (
          <>
            <p style={{ marginRight: "3px" }}>
              <s>{formatoBrasileiro.format(ValorComDesconto)}</s>{" "}
              <span
                style={{
                  backgroundColor: "rgba(30, 180, 100, 0.2)",
                  borderRadius: "10px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  fontSize: "0.8rem",
                  color: "rgb(31, 183, 108)",
                }}
              >
                {" "}
                {percentual}{" "}
              </span>
            </p>
            <p style={{ marginRight: "3px", color: "rgb(31, 183, 108)" }}>
              {DadosPrecoProduto(produto.produto)}
            </p>
          </>
        ) : (
          <>
            <p style={{ marginRight: "3px" }}>
              {DadosPrecoProduto(produto.produto)}
            </p>
          </>
        )}

        {produto.produto.valor_delivery > 0 ? (
          <p style={{ fontSize: "10px", color: "rgba(0,0,0,0.5)" }}>
            Delivery +{" "}
            {formatoBrasileiro.format(produto.produto.valor_delivery)}un
          </p>
        ) : null}
      </h4>
    </div>
  );
};

export function DadosPrecoProduto(produto) {
  var Retorno = "";

  if (parseFloat(produto.pizza) > 0) {
    var valorMin = parseFloat(produto.pizza_min);
    var valorMax = parseFloat(produto.pizza_max);
    if (parseFloat(produto.pizza_tipo) !== 0) {
      valorMin = parseFloat(valorMin) * parseFloat(produto.pizza_qtd);
      valorMax = parseFloat(valorMax) * parseFloat(produto.pizza_qtd);
    }
    Retorno = "Apartir de " + formatoBrasileiro.format(valorMin);
    // Retorno =
    //   "De " +
    //   formatoBrasileiro.format(valorMin) +
    //   " até " +
    //   formatoBrasileiro.format(valorMax);
  } else {
    if (produto.preco_item == 0) {
      Retorno = "Apartir de " + formatoBrasileiro.format(produto.min);
    } else {
      Retorno = formatoBrasileiro.format(produto.preco_item);
    }
  }

  return Retorno;
}
