import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { API } from "../../../conexao/api";
import { PrimeiraLetraMaiuscula, formatarNumeroCelular } from "../../util/primeiraLetraMaiuscula";
import { formatoBrasileiro } from "../../util/formatacaoMoeda";



export function PageDadosPIX() {
    const [Buscar, setBuscar] = useState('');
    const [DadosCarregados, setDadosCarregados] = useState({});



    useEffect(() => {
        //console.log(Buscar.length);


        if (Buscar.length >= 5) {

            Busca();
        }

    }, [Buscar]);

    function Busca() {

        const link = "generica.php?tabela=ws_pedidos&where=id='" + Buscar + "'";

        API.get(link).then(
            function (response) {
                //console.log(response.data[0]);
                setDadosCarregados(response.data[0])


            }
        );
    }

    function LiberaPix() {

        API.get(`libera-pix.php?codigo=${Buscar}`).then(function (response) {
            Busca();
        });
    }

    return (<>
        <div>
            <div style={{ height: '60px', width: '100%', backgroundColor: 'rgb(0,189,174)', display: 'flex', color: 'white', padding: '10px' }}>

                <h1>Consulta Pedido</h1>


            </div>

            <div style={{ padding: '10px' }}>


                <span className="p-input-icon-left" style={{ width: '100%', marginBottom: '10px' }}>
                    <i className="pi pi-search" />
                    <InputText style={{ width: '100%', border: '0' }} placeholder="Código do Pedido" value={Buscar} onChange={(e) => { setBuscar(e.target.value) }} />
                </span>

                {/* <div>
                    <h6>Data e Hora</h6>
                    <h3>10/10/2023 15:00</h3>
                </div> */}
                <div>
                    <h6>Pagamento</h6>
                    <h3>{DadosCarregados?.forma_pagamento}</h3>
                </div>
                <div>
                    <h6>Cliente</h6>
                    <h3>{DadosCarregados?.telefone ? formatarNumeroCelular(DadosCarregados?.telefone) : ''} - {PrimeiraLetraMaiuscula(DadosCarregados?.nome)}</h3>
                </div>
                <div>
                    <h6>Produtos R$</h6>
                    <h3>{DadosCarregados.sub_total ? formatoBrasileiro.format(DadosCarregados.sub_total) : ''}</h3>
                </div>
                <div>
                    <h6>Taxa</h6>
                    <h3>{DadosCarregados.valor_taxa ? formatoBrasileiro.format(DadosCarregados.valor_taxa) : ''}</h3>
                </div>
                <div>
                    <h6>Total</h6>
                    <h3>{DadosCarregados.total ? formatoBrasileiro.format(DadosCarregados.total) : ''}</h3>
                </div>
                <div>
                    <h6>Status</h6>
                    <h3>{DadosCarregados.status ? PrimeiraLetraMaiuscula(DadosCarregados.status) : ''}</h3>
                </div>
                {PrimeiraLetraMaiuscula(DadosCarregados.status) == 'Aguardando Pix Online' ? <>
                    <div onClick={LiberaPix} style={{ marginTop: '20px', width: '100%', backgroundColor: 'green', color: 'white', cursor: 'pointer', display: 'flex', textAlign: 'center', alignItems: 'center', height: '40px', borderRadius: '5px' }}>
                        <h3 onClick={LiberaPix} style={{ alignContent: 'center', justifyContent: 'center', justifyItems: 'center', marginTop: '5px', width: '100%' }}>Liberar Pedido</h3>
                    </div>
                </> : null}
            </div>


        </div>


    </>)
}