import { useParams, Link } from "react-router-dom";

function getBaseUrlWithFirstSegment() {
    const url = window.location.href; // Pega a URL completa
    const parts = url.split('/'); // Divide a URL em segmentos

    // Retorna apenas até o primeiro segmento após o domínio
    return parts.length >= 4 ? `${parts[2]}/${parts[3]}/` : url;
}

export function BuscaLocal(Categoria) {
    // const { empresa } = useParams();
    Categoria = getBaseUrlWithFirstSegment() + Categoria
    return localStorage.getItem(Categoria);
}


const products = [
    {
        "marca": "BRAHMA",
        "produto": [
            { "name": "BRAHMA" },
            { "name": "BRAHMA 600ML" },
            { "name": "BRAHMA DUPLO MALTE" },
            { "name": "BRAHMA GARRAFA 600ML" },
            { "name": "BRAHMA LATA 350ML" },
            { "name": "BRAHMA LITRÃO" },
            { "name": "BRAHMA ZERO ÁLCOOL LATA 350ML" }
        ]
    },
    {
        "marca": "BUDWEISER",
        "produto": [
            { "name": "BUDWEISER LATA 350ML" },
            { "name": "BUDWEISER 600ML" },
            { "name": "BUDWEISER LITRÃO" },
            { "name": "BUDWEISER LONG NECK 350ML" }
        ]
    },
    {
        "marca": "COCA COLA",
        "produto": [
            { "name": "COCA COLA 2L" },
            { "name": "COCA COLA" },
            { "name": "COCA COLA 1,5L" },
            { "name": "COCA COLA 600ML" },
            { "name": "COCA COLA LATA 350ML" },
            { "name": "COCA COLA ZERO" },
            { "name": "COCA COLA ZERO 600ML" },
            { "name": "COCA COLA ZERO LATA 350ML" },
            { "name": "COCA COLA LATA ZERO" }
        ]
    },
    {
        "marca": "DEVASSA",
        "produto": [
            { "name": "DEVASSA LITRÃO" },
            { "name": "DEVASSA 600ML" }
        ]
    },
    {
        "marca": "EISENBAHN",
        "produto": [
            { "name": "EISENBAHN LONG NECK 350ML" }
        ]
    },
    {
        "marca": "ENERGETICO",
        "produto": [
            { "name": "ENERGETICO MONSTER" },
            { "name": "ENERGETICO MONSTER LATA" },
            { "name": "ENERGÉTICO RED BULL" }
        ]
    },
    {
        "marca": "FANTA",
        "produto": [
            { "name": "FANTA LARANJA 2L" },
            { "name": "FANTA LARANJA 600ML" },
            { "name": "FANTA LARANJA LATA 350ML" },
            { "name": "FANTA UVA 2L" },
            { "name": "FANTA UVA LATA 350ML" }
        ]
    },
    {
        "marca": "GUARANÁ",
        "produto": [
            { "name": "GUARANÁ 1,5L" },
            { "name": "GUARANÁ 2L" },
            { "name": "GUARANÁ 600ML" },
            { "name": "GUARANÁ ANTÁRTICA 600ML" },
            { "name": "GUARANÁ ANTARTICA LATA 350ML" },
            { "name": "GUARANÁ LATA" },
            { "name": "GUARANÁ ZERO" }
        ]
    },
    {
        "marca": "PEPSI",
        "produto": [
            { "name": "PEPSI 1L" },
            { "name": "PEPSI 2L" },
            { "name": "PEPSI 600ML" },
            { "name": "PEPSI LATA 350ML" },
            { "name": "PEPSI TWIST 2L" },
            { "name": "PEPSI TWIST 600ML" },
            { "name": "PEPSI TWIST LATA 350ML" }
        ]
    },
    {
        "marca": "PILSEN",
        "produto": [
            { "name": "PILSEN 600ML" }
        ]
    },
    {
        "marca": "RED BULL",
        "produto": [
            { "name": "RED BULL 250ML" },
            { "name": "RED BULL 355ML" }
        ]
    },
    {
        "marca": "SCHIN",
        "produto": [
            { "name": "SCHIN" },
            { "name": "SCHIN LATA" }
        ]
    },
    {
        "marca": "SCHWEEPS",
        "produto": [
            { "name": "SCHWEEPS GREEN" },
            { "name": "SCHWEPPES CITRUS" }
        ]
    },
    {
        "marca": "SODA",
        "produto": [
            { "name": "SODA" },
            { "name": "SODA 2L" },
            { "name": "SODA 600ML" },
            { "name": "SODA LATA 350ML" },
            { "name": "SODA LIMONADA" },
            { "name": "SODA LIMONADA 2L" }
        ]
    },
    {
        "marca": "SPATEN",
        "produto": [
            { "name": "SPATEN 600ML" },
            { "name": "SPATEN LONG NECK 350ML" }
        ]
    },
    {
        "marca": "SPRITE",
        "produto": [
            { "name": "SPRITE 600ML" },
            { "name": "SPRITE LATA 350ML" }
        ]
    },
    {
        "marca": "SUB ZERO",
        "produto": [
            { "name": "SUB ZERO 600ML" },
            { "name": "SUB ZERO LATA 350ML" }
        ]
    },
    {
        "marca": "SUKITA",
        "produto": [
            { "name": "SUKITA 2L" },
            { "name": "SUKITA LARANJA 2L" },
            { "name": "SUKITA LATA 350ML" },
            { "name": "SUKITA UVA 2L" }
        ]
    },
    {
        "marca": "TEEM",
        "produto": [
            { "name": "TEEM 2L" }
        ]
    }
]


// function levenshtein(a, b) {
//     if (a.length === 0) return b.length;
//     if (b.length === 0) return a.length;

//     const matrix = [];

//     for (let i = 0; i <= b.length; i++) {
//         matrix[i] = [i];
//     }

//     for (let j = 0; j <= a.length; j++) {
//         matrix[0][j] = j;
//     }

//     for (let i = 1; i <= b.length; i++) {
//         for (let j = 1; j <= a.length; j++) {
//             if (b.charAt(i - 1) == a.charAt(j - 1)) {
//                 matrix[i][j] = matrix[i - 1][j - 1];
//             } else {
//                 matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1));
//             }
//         }
//     }

//     return matrix[b.length][a.length];
// }

// Esta é uma função básica de divisão em sílabas - pode precisar de ajustes para lidar com português corretamente.
function splitIntoSyllables(word) {
    return word.split(' ');
}

export function findMostSimilar(productName) {
    return levenshtein('ALLAN', 'ALLAN2')
    // let jsonData = products;
    // const productNameSyllables = splitIntoSyllables(productName);

    // let potentialMatches = [];

    // jsonData.forEach(brand => {
    //     brand.produto.forEach(product => {
    //         const productSyllables = splitIntoSyllables(product.name);
    //         let totalDistance = 0;

    //         productNameSyllables.forEach((syl, index) => {
    //             const productSyl = productSyllables[index] || "";
    //             totalDistance += levenshtein(syl, productSyl);
    //         });

    //         const averageDistance = totalDistance / productNameSyllables.length;
    //         const similarity = (1 - averageDistance / Math.max(...productNameSyllables.map(s => s.length))) * 100;

    //         if (similarity > 1) {
    //             potentialMatches.push({
    //                 name: product.name,
    //                 similarity: similarity
    //             });
    //         }
    //     });
    // });

    // if (potentialMatches.length === 0) return null;

    // potentialMatches.sort((a, b) => b.similarity - a.similarity);

    // return {
    //     match: potentialMatches[0].name,
    //     similarity: potentialMatches[0].similarity.toFixed(2) + '%'
    // };
}





function removeAccents(str) {
    const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ';
    const without = 'AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn';

    return str.split('').map((char) => {
        const index = accents.indexOf(char);
        return index !== -1 ? without[index] : char;
    }).join('');
}

function levenshtein(a, b) {
    if (a.length === 0) return b.length;
    if (b.length === 0) return a.length;

    const matrix = [];

    for (let i = 0; i <= b.length; i++) {
        matrix[i] = [i];
    }

    for (let j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }

    for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1) == a.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1));
            }
        }
    }

    return matrix[b.length][a.length];
}

function similarity(word1, word2) {
    const beveragePackagingMap = {
        '350ML': 'LATA',
        '600ML': 'GARRAFA',
        // Adicione outros mapeamentos conforme necessário
        '1L': 'GARRAFÃO',
        '2L': 'PET'
    };

    function getPackagingForSize(size) {
        return beveragePackagingMap[size.toUpperCase()] || 'DESCONHECIDO';
    }


    word1 = removeAccents(word1).toUpperCase();
    word2 = removeAccents(word2).toUpperCase();

    if (word1.includes('0ML') && word2.includes('0ML')) {

        const baseWord1 = word1.replace(/\d+ML/, '');
        const baseWord2 = word2.replace(/\d+ML/, '');

        if (word1 != word2) {
            return 0;
        }
    } else {
        if (word1.includes('0ML')) {
            let newnome = beveragePackagingMap[word1.toUpperCase()] || 'DESCONHECIDO';

            if (newnome != 'DESCONHECIDO') {
                if (newnome == word2) {
                    console.log('novonome ' + newnome + ' = ' + word2);
                    return 100;
                }
            }
        }
        if (word2.includes('0ML')) {
            let newnome = beveragePackagingMap[word2.toUpperCase()] || 'DESCONHECIDO';

            if (newnome != 'DESCONHECIDO') {
                if (newnome == word1) {
                    return 100;
                }
            }
        }
    }


    const distance = levenshtein(word1, word2);
    const similarity = (1 - distance / Math.max(word1.length, word2.length)) * 100;

    return similarity;
}




function BuscaIguais(productName) {
    const productNameSyllables = splitIntoSyllables(productName);
    let jsonData = products;
    let potentialMatches = [];

    jsonData.forEach(brand => {
        brand.produto.forEach(product => {
            const productSyllables = splitIntoSyllables(product.name);
            let totalDistance = 0;
            let qtd = 0

            productNameSyllables.forEach((syl, index) => {
                const productSyl = productSyllables[index] || "";
                if (productSyl != "" && syl != '') {
                    totalDistance += similarity(syl, productSyl);
                    qtd++

                }

            });
            let percentual = totalDistance / qtd;
            console.log(product.name + ' - ' + percentual + '% qtd ' + qtd + ' tot ' + totalDistance + ' percentual 2 ' + (totalDistance / (productSyllables.length + productNameSyllables.length)));




            if (percentual > 40) {
                potentialMatches.push({
                    name: product.name,
                    similar: percentual
                });
            }
        });
    });

    if (potentialMatches.length === 0) return null;

    potentialMatches.sort((a, b) => {
        // Ordenar pela maior porcentagem de similaridade
        if (a.similar > b.similar) {
            return -1;
        }
        if (a.similar < b.similar) {
            return 1;
        }

        // Se as porcentagens de similaridade forem iguais, ordenar pelo tamanho do nome
        return b.name.length - a.name.length;
    });

    // potentialMatches.sort((a, b) => b.similarity - a.similarity);

    return potentialMatches;


}

// console.log(BuscaIguais('BRAHMA LATA'));
// console.log(similarity('600ML', '600ML'))