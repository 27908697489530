import { CorFonte, CorFundo } from "../../util/validaAbertura";
import { FaGift } from 'react-icons/fa';


export function FidelidadeProduto({ data, qtd }) {

    return (<>
        {data.fidelidade_valor > 0 ? <>
            <div style={{ border: '1px solid rgba(0,0,0,0.5)', borderRadius: '5px', marginTop: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                    <div
                        style={{
                            backgroundColor: CorFundo(),
                            padding: '10px',
                            marginRight: '15px',
                            borderRadius: '50%', // Para fazer o fundo circular
                            display: 'flex',
                            alignItems: 'center', // Centralizar verticalmente
                            justifyContent: 'center', // Centralizar horizontalmente
                            width: '36px',  // Definindo largura
                            maxHeight: '36px',
                            maxWidth: '36px'
                        }}
                    >
                        <FaGift color={CorFonte()} size={18} />
                    </div>
                    <div>
                        <div style={{ fontSize: '0.8rem' }}>Resgate por {qtd > 0 ? parseFloat(data.fidelidade_valor) * qtd : data.fidelidade_valor} pontos ao finalizar o pedido</div>
                        <div style={{ fontSize: '0.8rem', color: 'rgba(0,0,0,0.5)' }}>Acumule pontos nas suas compras e troque por prêmios e descontos exclusivos!</div>

                        {/* <div style={{ fontSize: '0.8rem' }}>A cada R$ 1,00 em compras você ganha 1 ponto que pode ser trocado por prêmios.</div>
                    <div style={{ fontSize: '0.8rem' }}><a href="#" style={{ textDecoration: 'underline', color: 'rgba(0,0,0,0.5)' }}>Clique aqui e consulte o regulamento</a></div> */}
                    </div>
                </div>
            </div>
        </> : null}
    </>)
}