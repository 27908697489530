import { useState } from "react";
import { BsTrash } from "react-icons/bs";
import { formatoBrasileiro } from "../../../util/formatacaoMoeda";
import { PrimeiraLetraMaiuscula } from "../../../util/primeiraLetraMaiuscula";
import { ConfirmDialog } from "primereact/confirmdialog"; // For <ConfirmDialog /> component
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import { FaMapMarkerAlt, FaMotorcycle } from "react-icons/fa";
import { Imagem } from "../../../imagem/imagem";
import { CorFonte, CorFundo } from "../../../util/validaAbertura";
import { Button } from "primereact/button";

export function ProdutoCarrinho(prop) {
  const [visible, setVisible] = useState(false);
  const [ForaEstoque, setForaEstoque] = useState(false);
  const [Ponto, setPonto] = useState(0);
  const [QuantidadeResgate, setQuantidadeResgate] = useState(0);
  const [ValorProduto, setValorProduto] = useState(0);
  const [ExclusivoVemBusca, setExclusivoVemBusca] = useState(
    parseInt(prop.produto.vembuscar) == 1
  );
  const [ExclusivoDelivery, setExclusivoDelivery] = useState(
    parseInt(prop.produto.delivery) == 1
  );
  console.log(prop.produto);

  const confirm = () => {
    prop.deletar(prop.index);

    setVisible(false);
  };

  const cancel = () => {
    setVisible(false);
  };

  const Deletar = () => {
    setVisible(true);
  };

  // Função que lida com a adição de pontos
  function Add() {
    if (prop.PontosDisponivel() >= Ponto) {
      prop.AddPontoUsado(Ponto, ValorProduto);
      setQuantidadeResgate(QuantidadeResgate + 1);
    }
  }

  // Função que lida com a remoção de pontos
  function Remove() {
    if (QuantidadeResgate > 0) {
      prop.RemovePontoUsado(Ponto, ValorProduto);
      setQuantidadeResgate(QuantidadeResgate - 1);
    }
  }

  // Verifica se a função de resgate de pontos está habilitada
  function HabilitadoPonto() {
    return (
      (prop.fidelidade && Ponto > 0 && prop.PontosDisponivel() >= Ponto) ||
      QuantidadeResgate > 0
    );
  }

  function ValidarExibicao(tipo) {
    if (prop.OpcaoSelecionada == "Delivery") {
      if (tipo == 1 && ExclusivoVemBusca) {
        prop.ProdutoExclusivoVembuscar(
          PrimeiraLetraMaiuscula(prop.produto.nome_produto)
        );
        return true;
      }
    }
    if (prop.OpcaoSelecionada == "Vem Buscar") {
      if (tipo == 1 && ExclusivoDelivery) {
        prop.ProdutoExclusivoDelivery(
          PrimeiraLetraMaiuscula(prop.produto.nome_produto)
        );
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <ConfirmDialog
        visible={visible}
        onHide={cancel}
        message={`Deseja excluir ${
          prop.produto.quantidade_selecionada
        }x - ${PrimeiraLetraMaiuscula(
          prop.produto.nome_produto
        )} ${formatoBrasileiro.format(prop.SomaTotal(prop.produto))}?`}
        header="Excluir"
        icon="pi pi-trash"
        footer={
          <div>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              onClick={cancel}
              className="p-button-text"
              style={{
                backgroundColor: CorFonte(),
                color: CorFundo(),
                border: "none",
              }} // Estilos para o botão de cancelar
            />
            <Button
              label="Excluir"
              icon="pi pi-check"
              onClick={confirm}
              style={{
                backgroundColor: CorFundo(),
                color: CorFonte(),
                border: "none",
              }} // Estilos para o botão de excluir
            />
          </div>
        }
      />
      <li className="product-item" style={styles.productItem}>
        <div style={styles.imageContainer}>
          <Imagem
            altura="100px"
            largura="100px"
            foto={prop.produto.url_foto}
            id={prop.produto.id_produto}
            ifood={prop.produto.url_foto_ifood}
            descricao={ForaEstoque ? "Fora de Estoque" : ""}
          />
        </div>
        <div className="product-info" style={styles.productInfo}>
          <h3 style={styles.productTitle}>
            {PrimeiraLetraMaiuscula(prop.produto.nome_produto)}
          </h3>
          <p style={styles.productDescription}>
            {PrimeiraLetraMaiuscula(prop.Descricao(prop.produto))}
          </p>
          <p style={styles.productPriceUnitario}>
            {prop.produto.quantidade_selecionada} x{" "}
            {formatoBrasileiro.format(prop.SomaTotal(prop.produto))}
          </p>
          <p style={styles.productPriceTotal}>
            Total:{" "}
            {formatoBrasileiro.format(
              prop.SomaTotal(prop.produto) * prop.produto.quantidade_selecionada
            )}
          </p>

          {HabilitadoPonto() && (
            <div style={styles.resgateContainer}>
              <p style={styles.resgateText}>
                Quantidade que deseja resgatar com seus pontos
              </p>
              <div style={styles.resgateControl}>
                <IoIosRemove
                  color={QuantidadeResgate > 0 ? "green" : "transparent"}
                  size={24}
                  onClick={Remove}
                  style={styles.resgateButton}
                />
                <p style={styles.resgateQuantity}>
                  <b>{QuantidadeResgate}</b>
                </p>
                <IoIosAdd
                  color={
                    prop.PontosDisponivel() >= Ponto ? "green" : "transparent"
                  }
                  size={24}
                  onClick={Add}
                  style={styles.resgateButton}
                />
              </div>
            </div>
          )}

          {ValidarExibicao(1) && (
            <p style={styles.exclusivoText}>
              <FaMapMarkerAlt /> Exclusivo Retirada
            </p>
          )}
          {ValidarExibicao(2) && (
            <p style={styles.exclusivoText}>
              <FaMotorcycle /> Exclusivo Delivery
            </p>
          )}
        </div>
        <span className="delete-icon" style={styles.deleteIcon}>
          <BsTrash size={25} onClick={Deletar} />
        </span>
      </li>
    </>
  );
}

const styles = {
  productItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    borderRadius: "8px", // Arredondamento dos cantos
    backgroundColor: "#f9f9f9", // Cor de fundo leve
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Sombra suave
    marginBottom: "15px", // Espaço entre os itens
  },
  imageContainer: {
    marginRight: "10px",
  },
  productInfo: {
    flex: 1,
    paddingLeft: "10px",
  },
  productTitle: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "#333",
  },
  productDescription: {
    fontSize: "0.9rem",
    color: "#666",
  },
  productPriceUnitario: {
    fontSize: "0.9rem",
    color: "#333",
  },
  productPriceTotal: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#333",
  },
  resgateContainer: {
    marginTop: "20px",
  },
  resgateText: {
    fontSize: "0.9rem",
    color: "rgb(31, 183, 108)",
  },
  resgateControl: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "120px",
  },
  resgateButton: {
    cursor: "pointer",
  },
  resgateQuantity: {
    margin: "0 10px",
    fontSize: "1.2rem",
  },
  exclusivoText: {
    marginTop: "10px",
    fontSize: "1rem",
    color: CorFundo(),
  },
  deleteIcon: {
    cursor: "pointer",
    color: CorFundo(),
  },
};

// Custom CSS for the ConfirmDialog buttons
const customConfirmDialogStyles = `
  .custom-confirm-dialog .p-confirm-dialog .p-dialog-footer .p-button {
    background-color: #ff5722 !important; /* Cor para o botão de "Excluir" */
    color: white !important;
  }
  .custom-confirm-dialog .p-confirm-dialog .p-dialog-footer .p-button-secondary {
    background-color: #000000 !important; /* Cor para o botão de "Cancelar" */
    color: white !important;
  }
`;

export default ProdutoCarrinho;
