import { useEffect, useState } from "react";
import { API } from "../../conexao/api";


export function HojeComeremos() {
  const [Sim, setSim] = useState(0)


  
  useEffect(() => {
    API.get('generica.php?tabela=sim_nao').then( 
      function (response) {
        ////console.timeLog(response.data)
        setSim(response.data[0]['sim'])
    });
  }, []);

    function OnEnter(){
        // alert('Enter');
      
      var teste = document.getElementById('move');
      
      var EixoX = Math.floor(Math.random() * Number(window.innerWidth))-150;
      var EixoY = Math.floor(Math.random() * Number(window.innerHeight))-150;
      if (EixoX < 100){
        EixoX = 0;
      }
      if (EixoY < 100){
        EixoY = 0;
      }
      
      
      
      
      teste.style.left = EixoX+'px';
      teste.style.top = EixoY+'px';

      API.get('comeremos/nao.php').then();      
      API.get('generica.php?tabela=sim_nao').then( 
        function (response) {
          ////console.timeLog(response.data)
          setSim(response.data[0]['sim'])
      });      
      
      }
      
      function AbrirYoutubiu(){
        window.open('https://youtu.be/Kd_phJHKVAM?t=5');
        API.get('comeremos/sim.php').then();              
        API.get('generica.php?tabela=sim_nao').then( 
          function (response) {
            ////console.timeLog(response.data)
            setSim(response.data[0]['sim'])
        });
      }

      
      

return (<>

<div style={{height: '100vh', width: '100vw', backgroundColor: '#5a0505', position: 'relative', display: 'flex', justifyContent:'center', alignItems: 'center'}}>
    <div >

    
    <h1 style={{fontFamily: 'Roboto', color: '#fff', margin: '10px'}}>
    Cuzinho hoje?
    </h1>
    <h3 style={{fontFamily: 'Roboto', color: '#fff', margin: '10px'}}>
    Contador de Felizardos: {Sim}
    </h3>
    <button style={{border: 'none', color: 'white', backgroundColor: 'green', borderRadius:'8px', width: '100px', height: '50px', boxShadow: '1px black', cursor: 'pointer'}} onClick={AbrirYoutubiu}>Sim</button>
    <button id='move' onMouseOver={OnEnter} onClick={OnEnter} style={{position: 'absolute', border: 'none', color: 'white', backgroundColor: 'rgba(255, 0, 0, 0.7)', borderRadius:'8px', width: '100px', height: '50px', marginLeft: '10px', cursor: 'pointer'}}>Não</button>
    

  </div>
  </div>
</>)

}    