export function PrimeiraLetraMaiuscula(str) {
  var letra = str;
  if (!letra == "") {
    letra = letra.toLowerCase(letra);
    letra = letra.replace("%20", " ");
    letra = letra.replace("%20", " ");
    letra = letra.replace("%20", " ");
    letra = letra.replace("%20", " ");
    letra = letra.replace("%20", " ");
    letra = letra.replace("%", " ");
    letra = letra.replace("%", " ");
    letra = letra.replace("%", " ");
    letra = letra.replace("%", " ");
    letra = letra.replace("%", " ");
    letra = letra.replace("%", " ");
    letra = letra.replace("%", " ");

    return letra
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  } else {
    return "";
  }
}

export function formatarNumeroCelular(numero) {
  // Remover todos os caracteres que não sejam números
  const numeroApenasDigitos = numero.replace(/\D/g, "");

  // Adicionar parênteses em torno do código de área e hífen entre os números
  const numeroFormatado = numeroApenasDigitos.replace(
    /^(\d{2})(\d{4,5})(\d{4})$/,
    "($1) $2-$3"
  );

  return numeroFormatado;
}
export function truncateString(str, maxLength) {
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength - 3) + "...";
}

export function DescricaoProduto(descricao, descricao2) {
  var novaString = "";

  try {
    novaString = descricao2.replace(/\bSem\b/g, "");
    novaString = novaString.replace(/\bsem\b/g, "");
  } catch (error) {}

  if (descricao.length > 0) {
    return PrimeiraLetraMaiuscula(descricao);
  } else {
    return PrimeiraLetraMaiuscula(novaString);
  }
}
