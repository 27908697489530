import { GravaLocal } from "../../conexao/localstorage";
import { BuscaLocal } from "../../conexao/localstorageBusca";
import ReactPixel from 'react-facebook-pixel';

function converterParaDataHoraBrasil(dataHora) {
    // Separar a data e a hora da string
    var partes = dataHora.split(" ");
    var data = partes[0];
    var hora = partes[1];

    // Criar um objeto Date a partir da string da data/hora
    var dataHoraUTC = new Date(data + "T" + hora + "Z");

    // Calcular a diferença de tempo entre o UTC e o horário do Brasil
    var diferencaHoras = -3; // horário de Brasília (UTC-3) 
    var diferencaMilissegundos = diferencaHoras * 60 * 60 * 1000;

    // Adicionar a diferença de tempo para converter a data/hora para o horário do Brasil
    var dataHoraBrasil = new Date(dataHoraUTC.getTime() + diferencaMilissegundos);

    // Formatar a data/hora no formato "DD/MM/YYYY HH:mm:ss"
    var dataBrasil = dataHoraBrasil.toLocaleDateString("pt-BR");
    var horaBrasil = dataHoraBrasil.toLocaleTimeString("pt-BR");
    var dataHoraBrasilFormatada = dataBrasil + " " + horaBrasil;

    return dataHoraBrasilFormatada;
}

export function PIXELFACEBOOK() {
    const dataAtual = new Date();

    // Obter a data no formato dia/mês/ano
    const dia = dataAtual.getDate();
    const mes = dataAtual.getMonth() + 1; // Os meses são indexados a partir de 0, então somamos 1 para obter o mês correto
    const ano = dataAtual.getFullYear();

    // Formatar a data no formato desejado (dd/mm/aaaa)
    const dataFormatada = `${dia}/${mes}/${ano}`;



    let pixel = process.env.REACT_APP_PIXEL || '4472875272936865'

    ////////console.log(pixel);

    let UltimoAcesso = BuscaLocal('ACESSO' + BuscaLocal('NOME_LINK_EMPRESA'));
    //////console.log(pixel);
    if (UltimoAcesso != dataFormatada) {
        GravaLocal('ACESSO' + BuscaLocal('NOME_LINK_EMPRESA'), dataFormatada);
        ReactPixel.init(pixel);
        ReactPixel.pageView();
    }



    return pixel
}