import { useState } from "react";
import {
  FaPercent,
  FaChevronDown,
  FaTachometerAlt,
  FaTag,
} from "react-icons/fa";
import { Button } from "primereact/button";
import { CorFonte, CorFundo } from "../util/validaAbertura";
import { formatoBrasileiro } from "../util/formatacaoMoeda";
import { Notificacao } from "../util/notificacao";
import { BuscaLocal } from "../../conexao/localstorageBusca";
import { GravaLocal } from "../../conexao/localstorage";

export function CupomDescontoViwer({ dados }) {
  const [isExpanded, setIsExpanded] = useState(false);

  if (dados == undefined) {
    return null;
  }

  var dadosUsuario = JSON.parse(BuscaLocal("dados_cliente"));

  if (dadosUsuario != null) {
    return null;
  }

  function formatarData(data) {
    const [ano, mes, dia] = data.split("-");
    return `${dia}/${mes}/${ano}`;
  }

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const ValidaCupomDescontoV = () => {
    // Implementar a lógica para validar o cupom
    console.log("Cupom aplicado!");
    Notificacao("sucesso", "Cupom Aplicado!");
    GravaLocal("CUPOM", dados.ativacao);
    setIsExpanded(false);
  };

  const styles = {
    container: {
      width: "100%",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
      marginTop: "20px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      padding: "15px",
      backgroundColor: "#fff",
      transition: "all 0.3s ease",
    },
    content: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    iconContainer: {
      backgroundColor: CorFundo(),
      padding: "10px",
      marginRight: "15px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "50px",
      height: "50px",
      border: `1px solid ${CorFonte()}`,
    },
    icon: {
      color: CorFonte(),
    },
    textContainer: {
      fontSize: "0.9rem",
      flex: 1,
    },
    title: {
      fontWeight: "bold",
      marginBottom: "5px",
      fontSize: "1rem",
      color: "#333",
    },
    description: {
      fontSize: "0.8rem",
      marginBottom: isExpanded ? "10px" : "0px",
      color: "#555",
    },
    link: {
      fontSize: "0.8rem",
      textDecoration: "underline",
      color: CorFundo(),
      cursor: "pointer",
    },
    chevronIcon: {
      transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
      transition: "transform 0.3s ease",
      cursor: "pointer",
    },
    expandedContent: {
      marginTop: "10px",
      fontSize: "0.8rem",
      color: "#555",
      lineHeight: "1.5",
    },
    buttonContainer: {
      marginTop: "15px",
      textAlign: "right",
    },
  };

  function Valor() {
    return parseInt(dados.type_discount) == 2
      ? formatoBrasileiro.format(dados.fixed_value)
      : `${Math.round(dados.porcentagem)}%`;
  }

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <div style={styles.iconContainer}>
          <FaTag style={styles.icon} size={16} />
        </div>
        <div style={styles.textContainer}>
          <div style={styles.title}>
            Ganhe {Valor()} off com o cupom <b>{dados.ativacao}</b>
          </div>
          <div style={styles.description}>Veja os detalhes e ative!</div>
          {isExpanded && (
            <div style={styles.expandedContent}>
              <p>- Aplique o cupom no carrinho de compras.</p>
              {parseFloat(dados.valor_min) > 0 ? (
                <p>
                  - Válido para a primeira compra acima de{" "}
                  {formatoBrasileiro.format(parseFloat(dados.valor_min))}.
                </p>
              ) : null}

              <p>- Oferta limitada até {formatarData(dados.data_validade)}.</p>
            </div>
          )}
        </div>
        <FaChevronDown
          style={styles.chevronIcon}
          size={20}
          onClick={handleToggle}
        />
      </div>
      {isExpanded && (
        <div style={styles.buttonContainer}>
          <Button
            label="Aplicar Cupom"
            icon="pi pi-tag"
            className="button-coupon"
            onClick={ValidaCupomDescontoV}
            style={{
              backgroundColor: CorFundo(),
              border: "1px solid " + CorFundo(),
              color: CorFonte(),
            }}
          />
        </div>
      )}
    </div>
  );
}
