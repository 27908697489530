import axios from "axios";

export const API = axios.create({
  baseURL: "https://ws.goopedir.com/v1/",
});

// Interceptor para logar os dados das requisições
API.interceptors.request.use(
  (config) => {
    console.log("Request:", {
      url: config.url,
      method: config.method,
      headers: config.headers,
      data: config.data,
      params: config.params,
    });
    return config;
  },
  (error) => {
    console.error("Request Error:", error);
    return Promise.reject(error);
  }
);

// Interceptor para logar os dados das respostas
// API.interceptors.response.use(
//   (response) => {
//     console.log("Response:", {
//       url: response.config.url,
//       method: response.config.method,
//       status: response.status,
//       data: response.data,
//       headers: response.headers,
//     });
//     return response;
//   },
//   (error) => {
//     console.error("Response Error:", error.response);
//     return Promise.reject(error);
//   }
// );
