import { useEffect, useState } from 'react';
import { Home } from '../../componentes/home/home';
import './index.css';
import { API } from '../../conexao/api';
import { Carousel } from 'primereact/carousel';
import ReactStars from "react-rating-stars-component";



export function HomeGooPedir() {


  useEffect(() => {

    let Body = { email: 'allancolobo@gmail.com', senha: 'Sousaboroso2602' }
    API.post('login.php', { data: Body }).then(
      function (response) {
        //////console.log(response);
      });
    // API.get('empresa_media.php').then(
    //   function (result) {
    //     setCliente(result.data);
    //     ////////console.log(result.data);
    //   });

    // API.get('pedidos_hoje.php').then(
    //   function (result) {

    //     setHoje(result.data);
    //     API.get('dados_dashboard.php').then(
    //       function (result) {

    //         setDados(result.data)
    //       }
    //     );

    //   }
    // );




  }, []);


  // const Page = () => {

  //   if (!process.env.REACT_APP_EMPRESA) {
  //     return <HomeLoading />
  //   } else {
  //     return <HomeLoading />
  //   }

  // }



  return (

    <>
      {/* <Page /> */}
      {/* <HomeLoading /> */}

    </>
  )
}