import { useState, useEffect, useRef } from "react";
import { PrimeiraLetraMaiuscula } from "../util/primeiraLetraMaiuscula";
import { CorFonte, CorFundo } from "../util/validaAbertura";
import { ProdutoComponente } from "./indexProduto";
import { FaSpinner } from "react-icons/fa";

let Desabilita = false;
let QuantidadeLoad = 0;

export function CategoriaComponent({ Categorias }) {
  const [Cat, setCat] = useState(0);
  const [Quantity, setQuantity] = useState(QuantidadeLoad);
  const divRefs = useRef([]);

  const handleScroll = () => {
    const scrollY = window.scrollY;

    divRefs.current.forEach((ref, index) => {
      if (
        ref &&
        scrollY >= ref.offsetTop &&
        (divRefs.current[index + 1]
          ? scrollY < divRefs.current[index + 1].offsetTop
          : true)
      ) {
        // setCat(index);
        //
      }
    });
  };

  useEffect(() => {
    QuantidadeLoad = 0;
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function Location(id) {
    Desabilita = true;

    CategoriaSelecionada(id);
    const element = document.getElementById("cat" + id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    Desabilita = false;
  }

  function CategoriaSelecionada(codigo) {
    if (codigo != Cat) {
      setCat(codigo);
      const element = document.getElementById("lis" + codigo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  useEffect(() => {
    if (Cat == 0) {
      if (Categorias[0] != undefined) {
        CategoriaSelecionada(Categorias[0]?.id);
      }
    }
  }, [Categorias]);

  function QuantidadeCarregada() {
    QuantidadeLoad += 1;
    setQuantity(QuantidadeLoad);
  }

  return (
    <>
      <div className="lista-categorias" style={{ marginBottom: "10px" }}>
        <ul>
          {Categorias.map((categoria) => (
            <li
              key={categoria.id}
              onClick={() => Location(categoria.id)}
              style={{ borderRadius: "5px" }}
              id={`lis${categoria.id}`}
            >
              <a
                style={{
                  backgroundColor: Cat == categoria.id && CorFundo(),
                  color: Cat == categoria.id && CorFonte(),
                }}
              >
                {PrimeiraLetraMaiuscula(categoria.nome_cat)}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div style={{ display: Quantity != Categorias.length ? "" : "none" }}>
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 8,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FaSpinner style={{ fontSize: "3rem" }} className="spinning" />
        </div>
      </div>

      {Categorias?.map((categoria, index) => (
        <div
          key={index}
          ref={(el) => (divRefs.current[categoria.id] = el)}
          style={{ display: Quantity != Categorias.length ? "none" : "" }}
        >
          <ProdutoComponente
            index={index}
            categoria={categoria}
            carregou={QuantidadeCarregada}
          />
        </div>
      ))}
    </>
  );
}
