import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { HomeGooPedir } from "../../goopedir/home";
import { Home } from "../home/home";
import { Mesa } from "../home/interno/mesa/mesa";
import { ResumoPedido } from "../resumoPedido/resumo";
import { HojeComeremos } from "./HojeComeremos";
import { LandPage } from "../../goopedir/landpage";
import { Helmet } from "react-helmet";
import { PageDadosPIX } from "../page/resume-pix";
import { PageMotoboy } from "../page/motoboy";
import { PageFidelidade } from "../page/fidelidade";
import { PageTermoPolitica } from "../../goopedir/termo-politica";

export function Rotas() {
  const ogImageUrl = "./logo-goopedir-b.png";
  return (
    <>
      {/* <Helmet>
        <meta property="og:image" content={ogImageUrl} />
      </Helmet> */}
      <BrowserRouter>
        <Routes>
          <Route path="/:empresa" element={<Home edit_imagem={false} />} />
          <Route
            path="/loja-altera-imagem/:empresa"
            element={<Home edit_imagem={true} />}
          />
          <Route path="/:empresa/:pedido" element={<ResumoPedido />} />
          <Route
            path="/:empresa/mesa/:codigo"
            element={<Home edit_imagem={false} />}
          />
          <Route
            path="/:empresa/desc/:cupom"
            element={<Home edit_imagem={false} />}
          />
          <Route path="/desc/:cupom" element={<Home edit_imagem={false} />} />
          <Route
            path="/partner/:partner"
            element={<Home edit_imagem={false} />}
          />

          <Route path="/mesa/:codigo" element={<Home edit_imagem={false} />} />
          <Route
            path="/U0U5S1JTQkRUMDFGVWtWTlQxTWdRMVU9"
            element={<HojeComeremos />}
          />
          <Route path="/landpage" element={<LandPage />} />
          <Route path="/admin" element={<HomeGooPedir />} />
          <Route path="/admin/dados/pix" element={<PageDadosPIX />} />
          <Route path="/motoboy" element={<PageMotoboy />} />

          {/* Fidelidade */}
          <Route path="/fidelidade" element={<PageFidelidade />} />
          <Route path="/:empresa/fidelidade" element={<PageFidelidade />} />

          <Route path="/termo-e-politica" element={<PageTermoPolitica />} />

          <Route
            path="*"
            element={
              process.env.REACT_APP_EMPRESA === undefined ? (
                <LandPage />
              ) : (
                <Home edit_imagem={false} />
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}
