import { GravaLocal } from "../../conexao/localstorage";
import {
  DescricaoProduto,
  PrimeiraLetraMaiuscula,
  truncateString,
} from "../util/primeiraLetraMaiuscula";
import { useState, useEffect, useRef } from "react";
import { CorFonte, CorFundo, UserId } from "../util/validaAbertura";
import { Produto } from "../home/produto/produto";
import { formatoBrasileiro } from "../util/formatacaoMoeda";
import { BuscaLocal } from "../../conexao/localstorageBusca";
import { useHref } from "react-router-dom";
import Modal from "../modal/modal";
import { API } from "../../conexao/api";
import { FaGift } from "react-icons/fa";
import { DadosProduto } from ".";

export function ProdutoComponente({ categoria, carregou }) {
  const [produtos, setProdutos] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const refs = useHref([]);

  function DadosPrecoProduto(produto) {
    var Retorno = "";

    if (parseFloat(produto.pizza) > 0) {
      var valorMin = parseFloat(produto.pizza_min);
      var valorMax = parseFloat(produto.pizza_max);
      if (parseFloat(produto.pizza_tipo) !== 0) {
        valorMin = parseFloat(valorMin) * parseFloat(produto.pizza_qtd);
        valorMax = parseFloat(valorMax) * parseFloat(produto.pizza_qtd);
      }

      Retorno = "Apartir de " + formatoBrasileiro.format(valorMin);
    } else {
      if (produto.preco_item == 0) {
        Retorno = "Apartir de " + formatoBrasileiro.format(produto.min);
      } else {
        Retorno = formatoBrasileiro.format(produto.preco_item);
      }
    }

    return Retorno;
  }

  async function BuscaProdutos(codigo) {
    if (codigo === "1134") {
      return null;
    }

    let URL = "produto_categoria.php?codigo=" + codigo;

    if (codigo !== 0) {
      GravaLocal("categoria_selecionada", codigo);

      try {
        const response = await API.get(URL); // Aqui usamos await para esperar a resposta

        if (response.data[0].user_id === UserId()) {
          carregou();
          return response.data;
        }
      } catch (error) {}
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const result = await BuscaProdutos(categoria.id);
      console.log(result);
      setProdutos(result);
    };

    fetchData();
  }, [categoria.id]);

  function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal ? (
        <>
          <Modal onClose={handleCloseModal} cor="Black">
            <Produto
              key={"prod_" + BuscaLocal("produto_selecionada")}
              codigo={BuscaLocal("produto_selecionada")}
              onClose={handleCloseModal}
              DadosPrecoProduto={DadosPrecoProduto}
            />
          </Modal>
        </>
      ) : (
        <div id={`cat${categoria.id}`} style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: `${parseInt(categoria.espacamento) + 3}px`,
              padding: `${parseInt(categoria.espacamento) * 8}px`,
              backgroundColor: categoria.cor_fundo
                ? categoria.cor_fundo
                : CorFundo(),
              borderRadius: `${categoria.borda_topo_esquerdo}px ${categoria.borda_topo_direito}px ${categoria.borda_inferior_esquerdo}px ${categoria.borda_inferior_direito}px`,
            }}
          >
            <h1
              style={{
                fontSize: `${categoria.font_nome}px`,
                color: categoria.cor_nome ? categoria.cor_nome : CorFonte(),
              }}
            >
              {PrimeiraLetraMaiuscula(categoria.nome_cat)}
            </h1>
            <h1
              style={{
                fontSize: `${categoria.fonte_descricao}px`,
                color: categoria.cor_descricao
                  ? categoria.cor_descricao
                  : CorFonte(),
              }}
            >
              {PrimeiraLetraMaiuscula(categoria.descricao)}
            </h1>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // gridTemplateColumns: isMobile()
              //   ? "repeat(2,1fr)"
              //   : "repeat(8,1fr)",
              gap: "10px",
              // maxWidth: "380px",
              // overflowX: 'auto',      // Adiciona rolagem horizontal quando necessário
              // gap: '10px',
              padding: "10px",
              // whiteSpace: 'nowrap'    // Evita que o conteúdo quebre para a próxima linha
            }}
          >
            {produtos?.map((product, index) => (
              <>
                <DadosProduto product={product} setShowModal={setShowModal} />
              </>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
