import './rodape.css';
import React, { useState } from "react";
import { BuscaLocal } from '../../../conexao/localstorageBusca';
import { Notificacao } from '../../util/notificacao';
import Modal from '../../modal/modal';
import { CarrinhoPedido } from '../carrinho/carrinho';
import { BsFillCartFill } from 'react-icons/bs';
import { CorFonte, CorFundo, MensalidadePaga } from '../../util/validaAbertura';

export function Rodape() {

  const [showModalCarrinho, setshowModalCarrinho] = useState(false);
  const [TipoCarrinho, setTipoCarrinho] = useState(0);

  function ValidaCarrinho() {
    var DadosCarrinho = JSON.parse(BuscaLocal('produtos_selecionados'));
    if (DadosCarrinho == null) { DadosCarrinho = [] }

    if (DadosCarrinho.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const Carrinho = () => {
    if (!ValidaCarrinho()) {
      Notificacao('', 'Ops... você não possui nenhum produto no carrinho! 😌');
    } else {
      setTipoCarrinho(0);
      setshowModalCarrinho(true);

    }
  }

  const Finalizacao = () => {
    if (!ValidaCarrinho()) {
      Notificacao('', 'Ops... você não possui nenhum produto no carrinho! 😌');
    } else {
      setTipoCarrinho(1);
      setshowModalCarrinho(true);
    }
  }

  const CloseModal = () => {
    setshowModalCarrinho(false);
  }



  return (
    <>
      {showModalCarrinho && (
        <Modal onClose={CloseModal} cor={'white'} index={20}>
          <CarrinhoPedido tipo={TipoCarrinho} onClose={CloseModal} />
        </Modal>
      )}
      {MensalidadePaga() ? <>
        <div style={{ padding: '0px', backgroundColor: 'white', position: 'fixed', bottom: '0px', height: '75px', width: '100vw' }}>
          <div style={{ height: '50px', width: '99%', padding: '10px', borderRadius: '10px', marginBottom: '20px', position: 'fixed', bottom: '0' }}>
            <button className="carrinho-button" onClick={Carrinho} style={{ backgroundColor: CorFundo(), color: CorFonte(), boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px" }} ><BsFillCartFill style={{ marginRight: '10px', backgroundColor: CorFundo(), color: CorFonte() }} />Carrinho</button>
          </div>
        </div>

      </> : null}

    </>
  )
}