function getBaseUrlWithFirstSegment() {
    const url = window.location.href; // Pega a URL completa
    const parts = url.split('/'); // Divide a URL em segmentos

    // Retorna apenas até o primeiro segmento após o domínio
    return parts.length >= 4 ? `${parts[2]}/${parts[3]}/` : url;
}

export function GravaLocal(Categoria, Valor) {
    // const { empresa } = useParams();
    Categoria = getBaseUrlWithFirstSegment() + Categoria;
    localStorage.setItem(Categoria, Valor);

    return Valor;
}

export function ApagaLocal(Categoria) {
    Categoria = getBaseUrlWithFirstSegment() + Categoria;
    localStorage.removeItem(Categoria);
}