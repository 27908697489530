import { ImagemProduto } from "./imagemProduto";

export function Imagem(props) {


    var cor = 'red';
    var corFont = 'white';
    if (props.descricao == 'Mais Vendido') {
        cor = 'rgba(3,42,97,255)';
    } else {

    }

    return (
        <div style={{ width: props.largura }}>
            {props.descricao != null ? <>

                <div style={{
                    backgroundColor: cor,
                    color: corFont,
                    // padding: '5px 10px',
                    fontWeight: 'bold',
                    fontSize: '10px',
                    textAlign: 'center',
                    // textTransform: 'uppercase'
                }}>{props.descricao}</div>
            </> : null}

            <div style={{ height: '100px', width: '100px' }}>
                <ImagemProduto foto={props.img_item} id={props.id} ifood={props.ifood}/>
            </div>


        </div>
    )
}