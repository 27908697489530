import React from "react";
import { FaGift } from "react-icons/fa";
import { CorFonte, CorFundo } from "../../util/validaAbertura";
import { BuscaLocal } from "../../../conexao/localstorageBusca";
import { Link } from "react-router-dom";

export function FidelidadeEmpresa() {
  var Empresa = JSON.parse(BuscaLocal("dados_empresa"));
  Empresa = Empresa[0];

  // if (Empresa?.fidelidade_status !== 1) return null;

  const styles = {
    container: {
      border: "1px solid rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
      marginTop: "20px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      padding: "15px",
      backgroundColor: "#fff",
    },
    content: {
      display: "flex",
      alignItems: "center",
    },
    iconContainer: {
      backgroundColor: CorFundo(),
      marginRight: "15px",
      borderRadius: "50%",
      //   width: "50px",
      //   height: "50px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: `1px solid ${CorFonte()}`,
      padding: "10px",
    },
    icon: {
      color: CorFonte(),
      fontSize: "20px", // Tamanho do ícone ajustado para caber bem no círculo
    },
    textContainer: {
      fontSize: "0.9rem",
    },
    title: {
      fontWeight: "bold",
      marginBottom: "5px",
      fontSize: "1rem",
      color: "#333",
    },
    description: {
      fontSize: "0.8rem",
      marginBottom: "10px",
      color: "#555",
    },
    link: {
      fontSize: "0.8rem",
      textDecoration: "underline",
      color: CorFundo(),
      cursor: "pointer",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <div style={styles.iconContainer}>
          <FaGift style={styles.icon} />
        </div>
        <div style={styles.textContainer}>
          <div style={styles.title}>Programa de Fidelidade</div>
          <div style={styles.description}>
            A cada R$ 1,00 em compras você ganha {Empresa.fidelidade_ponto}{" "}
            ponto(s) que podem ser trocados por prêmios e descontos exclusivos.
          </div>
          <Link to={"/fidelidade"}>
            <div style={styles.link}>Clique aqui e consulte o regulamento</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
