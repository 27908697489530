import axios from "axios";
import { API } from "../../conexao/api";
import { BuscaLocal } from "../../conexao/localstorageBusca";

function isImageUrlValid(url) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function () {
            resolve(true);
        };
        img.onerror = function () {
            reject(false);
        };
        img.src = url;
    });
}





export async function FotoProduto(Imagem, codigo) {
    var dados = [];
    var Foto = '/sem-foto.jpg';
    await fetch('https://fotos.goopedir.com/', {
        headers: {
            'nome': codigo,
            'Content-Type': 'application/json'
        }
    })
        .then(response => response.json())
        .then(data => {
            dados = data;

            ////////////console.log(dados.status);
            // Trata a resposta da requisição

            if (!dados.status) {

                if (Imagem == 'sem-foto') {

                } else if (Imagem == 'false') {

                } else {
                    Foto = 'https://goopedir.com/uploads/' + Imagem;
                }

            } else {
                Foto = dados.link;
            }

            return Foto;
        })
        .catch(error => {
            // Trata o erro da requisição
            // return Foto;
        });





    // 
    // const Fotos = axios.create({
    //     baseURL: "https://fotos.goopedir.com/",
    //     headers: {
    //         'nome': codigo,
    //         'Content-Type': 'application/json'
    //     }
    // });

    //  Fotos.get().then(
    //     function (response) {

    //        
    //         ////////////console.log(dados['status']);
    //         

    //             var dadosEmpresa = JSON.parse(BuscaLocal('dados_empresa'));
    //            




    //             isImageUrlValid(Foto)
    //                 .then((isValid) => {

    //                 })
    //                 .catch((error) => {
    //                     // ////////////console.log(Foto);
    //                     API.get("produto_sem_foto.php?codigo=" + Imagem).then(
    //                         function (response) {
    //                             // ////////////console.log(response);

    //                         }
    //                     );


    //                 });
    //         } else {
    //             
    //         }

    //         return Foto;




    //     }
    // );




}