import { toast } from "react-toastify";

//https://fkhadra.github.io/react-toastify/introduction/

export function Notificacao(Tipo, Descricao) {
    
    if (Tipo == 'sucesso') {
        toast.success(Descricao, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "dark",
        });
    } else if (Tipo == 'erro') {
        toast.error(Descricao, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "dark",
        });
    } else {
        toast(Descricao, {
            position: "top-center",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "dark",
        });
    }

}