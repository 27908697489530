import { useState } from "react";
import { API } from "../../../../conexao/api";
import { GravaLocal } from "../../../../conexao/localstorage";
import { BuscaLocal } from "../../../../conexao/localstorageBusca";
import { PrimeiraLetraMaiuscula } from "../../../util/primeiraLetraMaiuscula";
import { CorFonte, CorFundo } from "../../../util/validaAbertura";
import ItensAdicional from "./itensAdicional";
import ItensPizza from "./itensPizza";

export function CategoriaAdicional(props) {
  var CategoriaPizza = [];
  const [Maximo, setMaximo] = useState(parseInt(props.dados["amount"]));
  const [Minimo, setMinimo] = useState(0);
  const [Obrigatorio, setObrigatorio] = useState(
    parseInt(props.dados["minimo"])
  );
  const [dadosAdicional, setDadosAdicional] = useState([]);

  if (props.tipo == "adicional") {
    if (dadosAdicional.length == 0) {
      console.log(
        "adicional_categoria.php?id=" +
          props.dados["id_itens"] +
          "&nome=" +
          props.dados["name_adicionais_cat"]
      );
      API.get(
        "adicional_categoria.php?id=" +
          props.dados["id_itens"] +
          "&nome=" +
          props.dados["name_adicionais_cat"]
      ).then(function (response) {
        var dados = response.data;
        if (dados != null && dados.length > 0) {
          setDadosAdicional(response.data);
        }
      });
    }
  }

  const Max = () => {
    setMinimo(parseInt(Minimo) + 1);
  };

  const Min = () => {
    setMinimo(parseInt(Minimo) - 1);
  };

  const MinPizza = () => {
    var value = BuscaLocal("quantidade_sabor_selecionado");
    if (value == "NaN") {
      value = 1;
    } else {
      value = parseInt(value) - 1;
    }
    GravaLocal("quantidade_sabor_selecionado", value);
  };

  const MaxPizza = () => {
    var value = BuscaLocal("quantidade_sabor_selecionado");
    if (value == "NaN") {
      value = 0;
    } else {
      value = parseInt(value) + 1;
    }

    GravaLocal("quantidade_sabor_selecionado", value);
  };

  const QuantidadeSelecionadaPizza = () => {
    var value = BuscaLocal("quantidade_sabor_selecionado");
    if (value == "NaN") {
      value = 0;
    }
    return value;
  };

  return (
    <>
      {props.tipo == "adicional" ? (
        <>
          <div
            className="header-adicional"
            style={{
              backgroundColor: CorFundo(),
              color: CorFonte(),
              height: "36px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            id={props.dados["id"]}
          >
            <span
              className="adicional-titulo"
              id={props.dados["id"]}
              style={{ backgroundColor: CorFundo(), color: CorFonte() }}
            >
              {PrimeiraLetraMaiuscula(props.dados["name_adicionais_cat"])}
            </span>

            <div style={{ display: "flex", alignItems: "center" }}>
              {Obrigatorio > 0 ? (
                <span
                  style={{
                    backgroundColor: CorFonte(),
                    color: CorFundo(),
                    // borderRadius: '5px',
                    fontSize: "10px",
                    height: "20px",
                    width: "60px",
                    textAlign: "center",
                    lineHeight: "20px",
                    marginRight: "7px",
                  }}
                >
                  Obrigatorio
                </span>
              ) : null}
              <span>
                {Minimo}/{Maximo}
              </span>
            </div>
          </div>

          {dadosAdicional.map((adicionais) => (
            <ItensAdicional
              key={adicionais.id}
              categoria={PrimeiraLetraMaiuscula(
                props.dados["name_adicionais_cat"]
              )}
              dados={adicionais}
              Min={Min}
              Max={Max}
              QuantidadeMaxima={Maximo}
              QuantidadeMinima={parseInt(props.dados["minimo"])}
              QuantidadeAtual={Minimo}
              add={props.add}
              remove={props.remove}
              DadosSelecionado={props.DadosSelecionado}
            />
          ))}
        </>
      ) : (
        <>
          <ItensPizza
            key={1}
            categoria={PrimeiraLetraMaiuscula(
              props.dados["name_adicionais_cat"]
            )}
            dados={props.dados}
            Min={MinPizza}
            Max={MaxPizza}
            QuantidadeMaxima={Maximo}
            QuantidadeMinima={0}
            QuantidadeAtual={QuantidadeSelecionadaPizza}
            add={props.add}
            remove={props.remove}
            DadosSelecionado={props.DadosSelecionado}
          />
        </>
      )}
    </>
  );
}
