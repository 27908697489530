import { InputNumber } from "primereact/inputnumber";
import { Cabecalho } from "../../cabecalho/cabecalho";
import { useEffect, useRef, useState } from "react";
import { InputMask } from "primereact/inputmask";
import { PrimeiraLetraMaiuscula } from "../../util/primeiraLetraMaiuscula";
import { CorFonte, CorFundo, UserId } from "../../util/validaAbertura";
import { API } from "../../../conexao/api";
import { InputText } from "primereact/inputtext";
import { GravaLocal } from "../../../conexao/localstorage";
import { BuscaLocal } from "../../../conexao/localstorageBusca";
import { FaGift } from "react-icons/fa";
import Modal from "../../modal/modal";
import { SaibaMais } from "./saibamais";
import { formatoBrasileiro } from "../../util/formatacaoMoeda";

export function PageFidelidade() {
  const [CPF, setCPF] = useState("");
  const [DataNascimento, setDataNascimento] = useState("");
  const [telefone, setPhone] = useState("");
  const [nome, setNome] = useState("");
  const [produtos, setProdutos] = useState([]);
  const [MeusPontos, setMeusPontos] = useState(0);
  const [Historico, setHistorico] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [Desconto, setDesconto] = useState({});

  function Load() {
    atualizaDados();
    API.get(
      "generica.php?tabela=ws_itens&where=user_id = " +
        UserId() +
        " and fidelidade_valor > 0 and disponivel = 1 order by fidelidade_valor"
    ).then(function (response) {
      if (response.data != null) {
        setProdutos(response.data);
      }
    });

    var Empresa = JSON.parse(BuscaLocal("dados_empresa"));
    Empresa = Empresa[0];
    console.log(Empresa.user_id);

    if (Empresa.fidelidade_pontos > 0) {
      setDesconto({
        fidelidade_valor: Empresa.fidelidade_pontos,
        desconto: Empresa.fidelidade_desc,
        user: Empresa.user_id,
      });
    }
  }

  function atualizaDados() {
    let cliente = JSON.parse(BuscaLocal("dados_cliente"));

    if (cliente != null) {
      setNome(PrimeiraLetraMaiuscula(cliente[0]["nome"]));
      setCPF(apenasNumeros(cliente[0]["cpf"]));
      setDataNascimento(apenasNumeros(cliente[0]["nascimento"]));
      setPhone(apenasNumeros(cliente[0]["telefone"]));

      API.get(
        `pontosfidelidade.php?user=${UserId()}&cpf=${apenasNumeros(
          cliente[0]["cpf"]
        )}`
      ).then(function (response) {
        setMeusPontos(response.data[0].saldo ? response.data[0].saldo : 0);
        setHistorico(response.data);
      });
    }
  }

  function apenasNumeros(str) {
    return str.replace(/[^0-9]/g, "");
  }
  const handlePhoneChange = (event) => {
    var celular = apenasNumeros(event.target.value);

    if (celular.length == 11) {
      setPhone(celular);

      API.get(
        "generica.php?tabela=ws_pedidos&where=telefone = '" +
          celular +
          "' and user_id = '" +
          UserId() +
          "' order by ws_pedidos.id desc limit 1"
      ).then(function (response) {
        GravaLocal("dados_cliente", JSON.stringify(response.data));
        setNome(PrimeiraLetraMaiuscula(response.data[0]["nome"]));
        setCPF(apenasNumeros(response.data[0]["cpf"]));
        setDataNascimento(apenasNumeros(response.data[0]["nascimento"]));
      });
    }
  };

  const handleCPFChange = (event) => {
    var cpf = apenasNumeros(event.target.value);

    if (cpf.length == 11) {
      setCPF(cpf);
      atualizaDados();
      API.get(
        "generica.php?tabela=ws_pedidos&where=cpf = '" +
          cpf +
          "' and user_id = '" +
          UserId() +
          "' order by ws_pedidos.id desc limit 1"
      ).then(function (response) {
        GravaLocal("dados_cliente", JSON.stringify(response.data));
        setNome(PrimeiraLetraMaiuscula(response.data[0]["nome"]));
        setPhone(apenasNumeros(response.data[0]["telefone"]));
        setDataNascimento(apenasNumeros(response.data[0]["nascimento"]));
      });
    }
  };
  function truncateString(str, maxLength) {
    if (str.length <= maxLength) {
      return str;
    }
    return str.slice(0, maxLength - 3) + "...";
  }

  const CardProduto = ({ produto }) => {
    let percentual = (MeusPontos / produto.fidelidade_valor) * 100;
    if (percentual > 100) {
      percentual = 100;
    }

    return (
      <>
        <div
          style={{
            height: "36px",
            position: "relative",
            border: `1px solid rgba(30, 180, 100, .2)`,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "#1fb76c",
            borderRadius: "5px",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              width: `${percentual}%`,
              height: "100%",
              backgroundColor: "rgba(30, 180, 100, .2)",
              position: "absolute",
              top: "0",
              left: "0",
            }}
          ></div>
          {isMobile() ? null : (
            <p style={{ zIndex: 99, marginLeft: "10px", color: "#1fb76c" }}>
              <b>Resgate com {produto.fidelidade_valor}</b>
            </p>
          )}
          <p
            style={{
              zIndex: 99,
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              margin: 0,
              color: "#1fb76c",
            }}
          >
            <b>{Math.round(percentual)}%</b>
          </p>
        </div>
        {isMobile() ? (
          <p style={{ zIndex: 99, color: "#1fb76c" }}>
            <b>Resgate com {produto.fidelidade_valor} pontos</b>
          </p>
        ) : null}
        <div>
          {percentual >= 100 ? (
            <spam style={{ color: "rgba(0,0,0,0.6)", fonteSize: "0.8rem" }}>
              Parabéns você já pode receber esse prémio!
            </spam>
          ) : null}
        </div>
      </>
    );
  };
  const CardHistorico = ({ data }) => {
    //console.log(data)
    return (
      <div style={{ position: "relative" }}>
        <div
          style={{
            backgroundColor:
              data.origem == 1 ? "rgba(30, 180, 100)" : "rgba(30, 180, 200)",
            padding: "10px",
            borderRadius: "50%", // Para fazer o fundo circular
            display: "flex",
            alignItems: "center", // Centralizar verticalmente
            justifyContent: "center", // Centralizar horizontalmente
            width: "50px", // Definindo largura
            maxHeight: "36px",
            maxWidth: "36px",
            // height: '50px'  // Definindo altura
            border: `1px solid${
              data.origem == 1 ? "rgba(30, 180, 100)" : "rgba(30, 180, 200)"
            }`,
            border: "1px solid black",
            borderRadius: "7px",
            position: "absolute",
            top: "25px",
            left: "35px",
          }}
        >
          <FaGift color={CorFonte()} size={24} />
        </div>

        <div
          style={{
            borderLeft: "1px dashed rgba(0,0,0,0.2)",
            marginLeft: "55px",
            paddingBottom: "20px",
            paddingLeft: "50px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start", // Alinha à direita
            height: "100px",
          }}
        >
          <div
            style={{
              alignContent: "center",
              alignItems: "center",
              marginTop: "15px",
            }}
          >
            <p>Você {data.origem == 1 ? "pontuou:" : "resgatou:"}</p>
            <b>{data.pontos} pontos</b>
            <p style={{ color: "rgba(0,0,0,.5)", fontSize: "0.7rem" }}>
              {data.processamento}
            </p>
          </div>
        </div>
      </div>
    );
  };

  function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Cabecalho onLoad={Load}>
        <div style={{ padding: "20px" }}>
          <h1>Dados</h1>
          <span
            className="p-float-label"
            style={{ marginTop: "25px", width: "100%" }}
          >
            <InputMask
              id="cpf"
              name="cpf"
              label="CPF"
              mask="999.999.999-99"
              floatLabel
              style={{ width: "100%" }}
              value={CPF}
              onChange={handleCPFChange}
            />
            <label htmlFor="cpf" style={{ marginLeft: "-10px" }}>
              CPF
            </label>
          </span>
          <span
            className="p-float-label"
            style={{ marginTop: "25px", width: "100%" }}
          >
            <InputMask
              id="nasci"
              name="nasci"
              label="Data de Nascimento"
              mask="99/99/9999"
              floatLabel
              style={{ width: "100%" }}
              value={DataNascimento}
              onChange={(e) => setDataNascimento(e.target.value)}
            />
            <label htmlFor="nasci" style={{ marginLeft: "-10px" }}>
              Data de Nascimento
            </label>
          </span>
          <span
            className="p-float-label"
            style={{ marginTop: "25px", width: "100%" }}
          >
            <InputMask
              id="telefone"
              name="telefone"
              label="Telefone"
              mask="(99) 99999-9999"
              floatLabel
              style={{ width: "100%" }}
              onChange={handlePhoneChange}
              value={telefone}
            />
            <label htmlFor="username" style={{ marginLeft: "-10px" }}>
              Celular
            </label>
          </span>

          <span
            className="p-float-label"
            style={{ marginTop: "25px", width: "100%" }}
          >
            <InputText
              id="nome"
              name="nome"
              label="Nome"
              floatLabel
              style={{ width: "100%" }}
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <label htmlFor="nome" style={{ marginLeft: "-10px" }}>
              Nome
            </label>
          </span>

          <div
            style={{
              width: "100%",
              height: isMobile() ? "110px" : "50px",
              display: isMobile() ? "" : "flex",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                width: isMobile() ? "100%" : "50%",
                height: "50px",
                border: "1px dotted black",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                padding: "10px",
                marginRight: isMobile() ? "0px" : "5px",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  backgroundColor: CorFundo(),
                  padding: "10px",
                  marginRight: "15px",
                  borderRadius: "50%", // Para fazer o fundo circular
                  display: "flex",
                  alignItems: "center", // Centralizar verticalmente
                  justifyContent: "center", // Centralizar horizontalmente
                  width: "50px", // Definindo largura
                  maxHeight: "36px",
                  maxWidth: "36px",
                  // height: '50px'  // Definindo altura
                  border: "1px solid " + CorFonte(),
                }}
              >
                <FaGift color={CorFonte()} size={24} />
              </div>
              <p>
                Saldo Atual de Pontos: <b> {` ${MeusPontos}`}</b>
              </p>
            </div>
            <div
              onClick={() => setShowModal(true)}
              style={{
                width: isMobile() ? "100%" : "50%",
                height: "50px",
                border: "1px solid black",
                textAlign: "center",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                padding: "10px",
                marginLeft: isMobile() ? "0px" : "5px",
              }}
            >
              <h1 style={{ width: "100%", textAlign: "center" }}>
                <b>Saiba Mais +</b>
              </h1>
            </div>
          </div>
          {showModal == false ? (
            <>
              <h1>Prêmios quem podem serem resgatados</h1>
              {Desconto ? (
                <div
                  style={{
                    borderRadius: "10px",
                    display: "flex",
                    padding: "10px",
                    marginBottom: "10px",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                  }}
                >
                  <img
                    src={`https://fotos.goopedir.com/fotos/empresa/${btoa(
                      Desconto.user
                    )}`}
                    alt=""
                    style={{
                      width: "100px",
                      height: "100px",
                      marginRight: "10px",
                    }}
                  />
                  <div style={{ width: "100%" }}>
                    <h3>Desconto Exclusivo</h3>
                    <p>
                      Desconto de R${" "}
                      {formatoBrasileiro.format(Desconto.desconto)}
                    </p>
                    <CardProduto produto={Desconto} />
                  </div>
                </div>
              ) : null}

              {produtos.length > 0 ? (
                produtos.map((produto, index) => (
                  <div
                    key={index}
                    style={{
                      borderRadius: "10px",
                      display: "flex",
                      padding: "10px",
                      marginBottom: "10px",
                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                    }}
                  >
                    <img
                      src={`https://fotos.goopedir.com/fotos/${btoa(
                        produto.id
                      )}`}
                      alt=""
                      style={{
                        width: "100px",
                        height: "100px",
                        marginRight: "10px",
                      }}
                    />
                    <div style={{ width: "100%" }}>
                      <h3>{PrimeiraLetraMaiuscula(produto.nome_item)}</h3>
                      <p>
                        {PrimeiraLetraMaiuscula(
                          truncateString(produto.descricao_item, 80)
                        )}
                      </p>
                      <CardProduto produto={produto} />
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </>
          ) : null}
          <h1>Meu histórico</h1>
          <div style={{ width: "100%", float: "left", marginBottom: "20px" }}>
            {Historico.map((dados, index) => (
              <>
                <CardHistorico data={dados} />
              </>
            ))}
          </div>
        </div>

        {showModal && (
          <Modal onClose={handleCloseModal} cor="Black">
            <div
              style={{
                padding: "20px",
                width: "100%",
                height: "100%",
                overflowX: "auto",
              }}
            >
              <SaibaMais close={handleCloseModal} />
            </div>
          </Modal>
        )}
      </Cabecalho>
    </>
  );
}
