import React from 'react';
import './modal.css';

function Modal(props) {
  var Cor = 'rgba(0, 0, 0, 0.5)';


  //style={{  zIndex: 20}}

  try {
    Cor = props.cor;
    var index = props.index;
  } catch (error) {
    var index = 18;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-auto">
        <button className="modal-close-button" style={{ color: Cor, zIndex: index }} onClick={props.onClose}>
          X
        </button>

        {props.children}

      </div>
    </div>
  );
}

export default Modal;
