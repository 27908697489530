// import "./index.css";

import { HeaderGooPedir } from "../component/header";

export function LandPage() {
  function isMobileDevice() {
    return /Mobi|Android/i.test(navigator.userAgent);
  }

  const CardFundoBranco = ({ children }) => {
    return (
      <>
        <div style={{ position: "relative", width: "100%" }}>
          <div
            style={{
              width: "100%",
              height: "100%", // importante para cobrir toda a div externa
              // background: "url('./goopedir/fundo.webp') center center/cover no-repeat",
              background: "url('./goopedir/body-bg.jpg') center center repeat",
              //center center repeat
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          ></div>

          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              zIndex: 2,
            }}
          ></div>
          {children}
        </div>
      </>
    );
  };

  const CardFundo = ({ children, color }) => {
    return (
      <>
        <div style={{ position: "relative", width: "100%" }}>
          <div
            style={{
              width: "100%",
              height: "100%", // importante para cobrir toda a div externa
              // background: "url('./goopedir/fundo.webp') center center/cover no-repeat",
              backgroundColor: color,
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          ></div>
          {/* 
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 2
                }}></div> */}
          {children}
        </div>
      </>
    );
  };

  const ItemImage = ({ url }) => {
    return (
      <>
        <img style={{ height: "72px", borderRadius: "20px" }} src={url} />
      </>
    );
  };

  window.location.href = "https://empresa.goopedir.com/";

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <iframe
          src={"https://empresa.goopedir.com/"}
          frameBorder="0"
          allowFullScreen
          style={{ width: "100%", height: "100%", border: "none" }}
        />
      </div>
    </>
  );
}
