import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
// import "primereact/resources/themes/lara-light-blue/theme.css";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import "./tema.css";
import { Rotas } from "./componentes/rota/rota";
import { ToastContainer } from "react-toastify";
import { VerMais } from "./componentes/home/vermais/vermais";
import { HomeGooPedir } from "./goopedir/home";
import { FormAutomatico } from "./goopedir/loja/componente/form-auto/formauto";
import { LandPage } from "./goopedir/landpage";
import axios from "axios";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <ToastContainer style={{ zIndex: 999 }} />
    <Rotas />

    {/* <App/> */}
    {/* <VerMais /> */}
    {/* <NewProductImg /> */}
    {/* <VerMais /> */}
    {/* <SistemaLocal/> */}

    {/* Abaixo é um teste pra criação de form dinamicamente mais facil, falta fazer a parte de adicionar/salvar tanto no front quanto no backend   */}
    {/* <FormAutomatico titulo="Categorias" subtitulo="Cadastro de categorias" tabela={'ws_cat'} primary={'id'} campos={campos} user={1} /> */}
    {/* <FormAutomatico titulo="Baner Promocional" subtitulo="Cadastro de categorias" tabela={'banner_promocional'} primary={'id_banner'} campos={camposBanner} user={1} /> */}
  </>
);
