

export function TermoUso() {
    return (<>
        <p>Por meio deste Termo de Uso, a GOOPEDIR LTDA, empresa de responsabilidade limitada situada na RUA JOÃO SERAFIM, nº 90, CRICIÚMA, SC, CEP 88.806-120 e inscrita no CNPJ/MF sob o nº 51.995.523/0001-56 ("Goopedir"), reafirma sua dedicação à privacidade e segurança de dados, apresentando sua Política de Privacidade. Esta política foi elaborada para esclarecer aos usuários ("Usuários") e negociantes ("Comerciantes", e junto com Usuários, denominados "Utilizadores") do aplicativo "Goopedir" ("Aplicativo"), do site goopedir.com/goopedir.com.br ("Website") e dos serviços associados ao Website e ao Aplicativo ("Serviços"), sobre como seus dados pessoais são manuseados e utilizados para proporcionar e otimizar os Serviços.</p>
        <br />
        <h1><b>I - Definições</b></h1>

        <p>Aplicativo: Plataforma da Goopedir para processamento de informações.</p>

        <p>Comerciantes: Empresas que usam os serviços da Goopedir.</p>

        <p>Dados Agregados: Dados coletados para análises gerais sem identificar usuários.</p>

        <p>Dados Pessoais: Informações que identificam usuários, obtidas pela Goopedir ou parceiros.</p>

        <p>Política de Privacidade: Normas sobre tratamento de dados no Goopedir.</p>

        <p>Serviços: Ofertas da Goopedir via aplicativo ou website.</p>

        <p>Usuários: Indivíduos com contas no Goopedir.</p>

        <p>Utilizadores: Usuários e Comerciantes.</p>

        <p>Website: Página eletrônica da Goopedir.</p>
        <br />
        <h1><b>II - Coleta de Dados</b></h1>

        <p>Os dados são coletados diretamente dos utilizadores, compartilhados por parceiros ou coletados automaticamente durante a navegação.</p>
        <br />
        <h1><b>III - Tipos de Dados Coletados</b></h1>

        <p>Os dados incluem informações de identificação, contato, transações e comportamento no website e aplicativo.</p>
        <br />
        <h1><b>IV - Uso dos Dados</b></h1>


        <p>A Goopedir utiliza dados para melhorar seus serviços, entender seus usuários e para fins de marketing e comunicação.</p>
        <br />
        <h1><b>V - Compartilhamento de Dados</b></h1>

        <p>A Goopedir compartilha dados com comerciantes, afiliados e terceiros para execução de serviços, mas sempre priorizando a privacidade dos usuários.</p>
        <br />
        <h1><b>VI - Armazenamento</b></h1>

        <p>A Goopedir mantém os dados enquanto os utilizadores estiverem ativos e conforme necessário para cumprir requisitos legais.</p>
        <br />
        <h1><b>VII - Cookies</b></h1>

        <p>A Goopedir usa cookies para melhorar a experiência do usuário e monitorar o uso do serviço.</p>
        <br />
        <h1><b>VIII - Direitos dos Usuários</b></h1>

        <p>Os usuários têm direitos estabelecidos pela LGPD, como acesso, correção e exclusão de seus dados.</p>
        <br />
        <h1><b>IX - Segurança de Dados</b></h1>

        <p>Os dados são armazenados em servidores seguros, mas os utilizadores são encorajados a serem cuidadosos com suas informações.</p>
        <br />
        <h1><b>X - Alterações na Política</b></h1>

        <p>A Goopedir pode atualizar esta política, comunicando os usuários e solicitando novos consentimentos quando necessário.</p>
        <br />
        <h1><b>XI - Jurisdição</b></h1>

        <p>Esta política é regida pela lei brasileira, e qualquer disputa será resolvida no Foro de Criciúma/SC.</p>
        <br />
        <h1><b>XII - Contato</b></h1>

        <p>Para dúvidas ou sugestões, entre em contato pelo e-mail contato@goopedir.com.</p>

    </>);
}