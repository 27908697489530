import { useState, memo } from "react";
import { GravaLocal } from "../../../../conexao/localstorage";
import { BuscaLocal } from "../../../../conexao/localstorageBusca";
import { formatoBrasileiro } from "../../../util/formatacaoMoeda";
import { Notificacao } from "../../../util/notificacao";
import { PrimeiraLetraMaiuscula } from "../../../util/primeiraLetraMaiuscula";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import { CorFundo } from "../../../util/validaAbertura";

function ItensPizza(props) {
  const [value, setValue] = useState(0);

  GravaLocal("pizza_quantidade", props.dados["qtd_sabor"]);

  const increment = () => {
    if (props.dados["qtd_sabor"] != props.QuantidadeAtual()) {
      var AddCategoriaArray = true;
      var PosicaoCategoriaArray = 0;

      if (props.DadosSelecionado[0].pizza.length == 0) {
        AddCategoriaArray = true;
      } else {
        for (var i = 0; i < props.DadosSelecionado[0].pizza.length; i++) {
          if (
            props.DadosSelecionado[0].pizza[i]["id_pizza"] == props.dados["id"]
          ) {
            AddCategoriaArray = false;
            PosicaoCategoriaArray = i;
            break;
          }
        }
      }

      // ////////////console.log(props.dados);
      if (AddCategoriaArray) {
        props.DadosSelecionado[0].pizza.push({
          id_pizza: props.dados["id"],
          sabor_pizza: PrimeiraLetraMaiuscula(
            props.dados["tipo"] + " - " + props.dados["sabor"]
          ),
          valor_pizza: props.dados["valor"],
          tipo_valor: props.dados["tipo_valor"],
          max: props.dados["qtd_sabor"],
          quantidade: 1,
        });
      } else {
        props.DadosSelecionado[0].pizza[PosicaoCategoriaArray].quantidade =
          value + 1;
      }
      var ValorSabores = parseFloat(BuscaLocal("soma_sabores"));
      ValorSabores =
        parseFloat(ValorSabores) + parseFloat(props.dados["valor"]);
      GravaLocal("soma_sabores", ValorSabores);

      var ValorSabores = parseFloat(BuscaLocal("soma_sabores_selecionado"));
      ValorSabores = parseFloat(ValorSabores) + 1;
      GravaLocal("soma_sabores_selecionado", parseFloat(ValorSabores));

      GravaLocal("pizza_tipo_valor", props.dados["tipo_valor"]);

      props.Max();
      props.add(0);
      setValue(value + 1);
    } else {
      Notificacao(0, "Quantidade máxima de sabores já foi atingida!");
    }
  };

  const decrement = () => {
    var Index = -1;
    var Achou = false;

    for (var i = 0; i < props.DadosSelecionado[0].pizza.length; i++) {
      if (props.DadosSelecionado[0].pizza[i]["id_pizza"] == props.dados["id"]) {
        Index = i;
        Achou = true;
        break;
      }
    }

    if (value > 0) {
      var ValorSabores = parseFloat(BuscaLocal("soma_sabores"));
      ValorSabores =
        parseFloat(ValorSabores) - parseFloat(props.dados["valor"]);
      GravaLocal("soma_sabores", ValorSabores);

      var ValorSabores = parseFloat(BuscaLocal("soma_sabores_selecionado"));
      ValorSabores = parseFloat(ValorSabores) - 1;
      GravaLocal("soma_sabores_selecionado", parseFloat(ValorSabores));

      props.remove(0);
      props.Min();
      props.DadosSelecionado[0].pizza[Index]["quantidade"] = value - 1;
      setValue(value - 1);
    } else {
      if (Achou) {
        props.DadosSelecionado[0].pizza[Index].splice(Index, 1);
      }
    }
  };

  return (
    <>
      <div
        className="input-with-buttons-container"
        style={{ borderBottom: `1px dotted ${CorFundo()}` }}
      >
        <div className="text-container">
          <h3>{PrimeiraLetraMaiuscula(props.dados["sabor"])}</h3>
          <h5>{PrimeiraLetraMaiuscula(props.dados["descricao"])}</h5>
          {props.dados["valor"] > 0 ? (
            <h4
              style={{
                color: "#ccc",
              }}
            >
              {formatoBrasileiro.format(props.dados["valor"])}{" "}
            </h4>
          ) : null}
          {/* <span>{PrimeiraLetraMaiuscula('TEST')}</span> */}
        </div>
        <div className="input-container">
          {/* <div className="buttons-container">
                    {value > 0 ? <button className="decrement-button" onClick={decrement}>-</button> : null}
                    <input className="input-box" type="text" value={value} disabled />
                    <button className="increment-button" onClick={increment}>+</button>
                </div> */}
          <div
            style={{
              width: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IoIosRemove
              color={value > 0 ? CorFundo() : "transparent"}
              size={36}
              onClick={decrement}
            />
            <p
              style={{
                width: "33%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: 0,
              }}
            >
              <b>{value}</b>
            </p>
            <div
              style={{
                width: "33%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IoIosAdd color={CorFundo()} size={36} onClick={increment} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(ItensPizza);
