import { FaCreditCard, FaMoneyBill, FaRegMoneyBillAlt } from 'react-icons/fa';
import { FaInstagram, FaFacebook, FaWhatsapp } from 'react-icons/fa';
import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet';
import * as L from 'leaflet';
import './vermais.css';
import { BuscaLocal } from '../../../conexao/localstorageBusca';
import { PrimeiraLetraMaiuscula } from '../../util/primeiraLetraMaiuscula';
import { ArrobaInsta, HorarioAberto, LinkFacebook, NumeroWhatsapp } from '../../util/validaAbertura';


export function VerMais() {
    // const position = [51.505, -0.09]
    // const iconeEstilo = {
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     margin: '0 20px'
    // };

    // const circuloEstilo = {
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     width: 70,
    //     height: 70,
    //     borderRadius: '50%',
    //     border: '3px solid #ccc'
    // };

    // const greenIcon = new L.Icon({
    //     iconUrl:
    //         'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
    //     shadowUrl:
    //         'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    //     iconSize: [25, 41],
    //     iconAnchor: [12, 41],
    //     popupAnchor: [1, -34],
    //     shadowSize: [41, 41]
    // });

    // GravaLocal('dados_empresa', JSON.stringify(response.data));
    var Dados = JSON.parse(BuscaLocal('dados_empresa'));

    ////////////console.log(Dados);

    return (<>
        <div>
            <div class="container">
                <div class="icon">
                    <img src="../empresa.svg" alt="ícone" width="40" height="40" />
                </div>
                <div class="content">
                    <h2>{PrimeiraLetraMaiuscula(Dados[0]['nome_empresa']) + ' • ' + PrimeiraLetraMaiuscula(Dados[0]['cidade_empresa']) + '-' + Dados[0]['end_uf_empresa']}</h2>
                    <p>{PrimeiraLetraMaiuscula(Dados[0]['end_rua_n_empresa']) + ', ' + PrimeiraLetraMaiuscula(Dados[0]['end_bairro_empresa'])}</p>
                </div>
            </div>
            <div class="container">
                <div class="container-social">
                    <div class="icon-social">
                        <img src="../whatsapp.svg" alt="ícone" width="30" height="30" />
                    </div>
                    <div class="content">
                        <p>Whatsapp</p>
                        <p>{NumeroWhatsapp()}</p>
                    </div>
                </div>
                {ArrobaInsta() != null ? <>
                    <div class="container-social">
                        <div class="icon-social">
                            <img src="../instagram.svg" alt="ícone" width="30" height="30" />
                        </div>
                        <div class="content">
                            <p>Instagram</p>
                            <p>{ArrobaInsta()}</p>
                        </div>
                    </div>
                </> : null}

                {LinkFacebook() != null ? <>
                    <div class="container-social">
                        <div class="icon-social">
                            <img src="../facebook.svg" alt="ícone" width="30" height="30" />
                        </div>
                        <div class="content">
                            <p>Facebook</p>
                            <p>{LinkFacebook()}</p>
                        </div>
                    </div>
                </> : null}


            </div>
            <div class="container" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                <h3>Horário de funcionamento </h3>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, width: '100px' }}>Segunda</div>
                    <div style={{ flex: 1, textAlign: 'center' }}>{HorarioAberto('segunda')}</div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, width: '100px' }}>Terça</div>
                    <div style={{ flex: 1, textAlign: 'center' }}>{HorarioAberto('terca')}</div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, width: '100px' }}>Quarta</div>
                    <div style={{ flex: 1, textAlign: 'center' }}>{HorarioAberto('quarta')}</div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, width: '100px' }}>Quinta</div>
                    <div style={{ flex: 1, textAlign: 'center' }}>{HorarioAberto('quinta')}</div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, width: '100px' }}>Sexta</div>
                    <div style={{ flex: 1, textAlign: 'center' }}>{HorarioAberto('sexta')}</div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, width: '100px' }}>Sábado</div>
                    <div style={{ flex: 1, textAlign: 'center' }}>{HorarioAberto('sabado')}</div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, width: '100px' }}>Domingo</div>
                    <div style={{ flex: 1, textAlign: 'center' }}>{HorarioAberto('domingo')}</div>
                </div>
                {/* <h4>Segunda-Feira <spam class="margin">10:00 até 15:00</spam></h4>
                <h4>Terça-Feira <spam style={{ marginLeft: '125px', }}>10:00 até 15:00</spam></h4>
                <h4>Quarta-Feira <spam style={{ marginLeft: '112px', }}>10:00 até 15:00</spam></h4>
                <h4>Quinta-Feira <spam style={{ marginLeft: '112px', }}>10:00 até 15:00</spam></h4>
                <h4>Sexta-Feira <spam style={{ marginLeft: '120px', }}>10:00 até 15:00</spam></h4>
                <h4>Sabado <spam style={{ marginLeft: '150px', }}>10:00 até 15:00</spam></h4>
                <h4>Domingo <spam class="margin">10:00 até 15:00</spam></h4> */}

            </div>
        </div>
        {/* <div style={{ height: '80%', width: '80%' }}>
            <MapContainer center={[18, 18]} zoom={13} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[18, 18]}>
                    <Popup>
                        A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                </Marker>
            </MapContainer>
        </div> */}


        {/*  */}

    </>
    )
}