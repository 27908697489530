import { BuscaLocal } from "../../../../conexao/localstorageBusca"
import { formatoBrasileiro } from "../../../util/formatacaoMoeda";
import { PrimeiraLetraMaiuscula } from "../../../util/primeiraLetraMaiuscula";
import { CorFonte, CorFundo } from "../../../util/validaAbertura";

export function SaibaMais({ close }) {
    let empresa = JSON.parse(BuscaLocal('dados_empresa'));
    empresa = empresa[0];
    console.log(empresa);
    return (<>
        <div style={{ overflowY: 'scroll', width: '100%' }}>
            <h1>PROGRAMA DE FIDELIDADE - {PrimeiraLetraMaiuscula(empresa.nome_empresa)}</h1>
            <div style={{ padding: '10px' }}>
                <b>Elegibilidade:</b>
                <div style={{ padding: '10px' }}>
                    <p>1. Para aderir, é necessário informar: celular, nome, CPF e data de nascimento.</p>
                    <p>2. O programa é voltado somente para pessoas físicas.</p>
                    <p>3. Ao entrar, o cliente afirma entender e aceitar os termos de uso, garantindo a veracidade de suas informações.</p>
                    <p>4. Ao efetuar compras, o cliente precisa se identificar usando CPF e celular.</p>
                </div>
            </div>
            <div style={{ padding: '10px' }}>
                <b>Pontuação:</b>
                <div style={{ padding: '10px' }}>
                    <p>1. Ganhe {empresa.fidelidade_ponto} ponto a cada R$ 1,00 gasto.</p>
                    <p>3. Consulte prêmios e resgate-os de acordo com seus pontos no site do {PrimeiraLetraMaiuscula((empresa.nome_empresa))}.</p>
                    <p>4. Compras online creditam pontos automaticamente ao cadastro do pedido.</p>
                    <p>5. Proibido vender ou transferir pontos. Infrações resultarão na exclusão do cliente do programa e zeramento de pontos.</p>
                    <p>6. Prêmios resgatados não acumulam pontos, mas outras compras no momento do resgate, sim.</p>
                </div>
            </div>
            <div style={{ padding: '10px' }}>
                <b>Resgate de Prêmios:</b>
                <div style={{ padding: '10px' }}>
                    <p>1. Prêmios podem ser visualizados e resgatados nesta página.</p>
                    <p>2. Para fazer a solicitação do resgate na finalização do pedido, o total dos produtos deve ser igual ou superior a R$ {formatoBrasileiro.format(empresa.fidelidade_min)}.</p>
                    <p>3. Apenas o titular pode fazer o resgate via site.</p>
                    <p>4. A Goopedir não é responsável por produtos e serviços de terceiros dados como prêmios.</p>
                </div>
            </div>
            <div style={{ padding: '10px' }}>
                <b>Validade e Termos:</b>
                <div style={{ padding: '10px' }}>
                    <p>1. Regulamento em vigor desde 01/10/2023, com duração indefinida</p>
                    <p>2. {PrimeiraLetraMaiuscula(empresa.nome_empresa)} pode fazer alterações no programa ou regulamento a qualquer momento..</p>
                    <p>3. O sistema Goopedir é utilizado para o programa. Ao se inscrever, o cliente concorda com os termos da Goopedir, disponíveis em <a href="https://pedir.goopedir.com/termo-e-politica">https://pedir.goopedir.com/termo-e-politica</a>.</p>
                    <p>Em caso de problemas com o regulamento, Goopedir se isenta, pois é apenas a fornecedora da tecnologia.</p>

                </div>
            </div>
            <div onClick={close} style={{ color: CorFonte(), backgroundColor: CorFundo(), borderRadius: '20px', height: '35px', width: '150px', textAlign: 'center', justifyContent: 'center', justifyItems: 'center', alignItems: 'center', display: 'flex' }}>
                Ok, Prosseguir
            </div>

        </div>
    </>)
}