
export function HeaderGooPedir() {
    return (<><header
        style={{
            backgroundColor: '#A8001C',
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between', // Isso alinhará o ícone do WhatsApp à direita
            position: 'fixed',
            top: 0,
            width: '100%',
            zIndex: 99999,
            fontSize: 24,
        }}
    >

        <img src="./logo-goopedir-b.png" style={{ width: '128px', marginLeft: '30px' }} />

    </header></>)
}