import { API } from "../../conexao/api";
import { GravaLocal } from "../../conexao/localstorage";
import { BuscaLocal } from "../../conexao/localstorageBusca";
import { formatoBrasileiro } from "./formatacaoMoeda";

var descricaoAbertura = "";

export function ValidaAbertura(datahora) {
  const agora = new Date();
  const dataHora = new Date(datahora);
  const diferencaTempo = agora.getTime() - dataHora.getTime();
  const diferencaMinutos = Math.floor(diferencaTempo / 1000 / 60);

  if (diferencaMinutos >= 5) {
    return "maior";
  } else {
    return "menor";
  }
}

function validarDataMensalidade(data) {
  const agora = new Date();
  const dataComparacao = new Date(data);
  return dataComparacao >= agora;
}

function obterDiaDaSemana() {
  const diasDaSemana = [
    "domingo",
    "segunda",
    "terca",
    "quarta",
    "quinta",
    "sexta",
    "sabado",
  ];
  const dataAtual = new Date();
  const diaDaSemana = diasDaSemana[dataAtual.getDay()];
  return diaDaSemana;
}

function validarData(data) {
  const dataAtual = new Date();
  const dataPassada = new Date(data);
  const diferenca = dataAtual.getTime() - dataPassada.getTime();
  const cincoMinutosEmMilissegundos = 300000;

  return diferenca > cincoMinutosEmMilissegundos;
}

function verificarHorarioAberturaFechamento(horaAbertura, horaFechamento) {
  const agora = new Date();
  const horaAtual = agora.getHours();
  const minutosAtuais = agora.getMinutes();
  const [horaAberturaHoras, horaAberturaMinutos] = horaAbertura.split(":");
  const [horaFechamentoHoras, horaFechamentoMinutos] =
    horaFechamento.split(":");
  const horaAberturaMinutosTotais =
    parseInt(horaAberturaHoras) * 60 + parseInt(horaAberturaMinutos);
  const horaFechamentoMinutosTotais =
    parseInt(horaFechamentoHoras) * 60 + parseInt(horaFechamentoMinutos);
  const horaAtualMinutosTotais = horaAtual * 60 + minutosAtuais;

  return (
    horaAtualMinutosTotais >= horaAberturaMinutosTotais &&
    horaAtualMinutosTotais <= horaFechamentoMinutosTotais
  );
}

export function PixMercvadoPago() {
  var dadosEmpresa = JSON.parse(BuscaLocal("dados_empresa"));
  if (dadosEmpresa[0]["access_token_mp"] != "") {
    return true;
  }
  return false;
}

export function StatusLoja() {
  return BuscaLocal("status_loja") == "true";
}

export async function ValidarBairro(bairro) {
  var dadosEmpresa = JSON.parse(BuscaLocal("dados_empresa"));
  const response = await API.get(
    "/bairro.php?codigo=" + dadosEmpresa[0]["user_id"] + "&bairro=" + bairro
  );
  console.log(response);
  try {
    if (response.data[0].user_id == dadosEmpresa[0]["user_id"]) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

export async function ValidaStatusLoja() {
  try {
    var dadosEmpresa = JSON.parse(BuscaLocal("dados_empresa"));
    //console.log(dadosEmpresa[0]['user_id'] == 1)
    // if (dadosEmpresa[0]["user_id"] == 1) {
    //   GravaLocal("status_loja", true);
    //   return true;
    // }
    const response = await API.get(
      "/horario.php?codigo=" + dadosEmpresa[0]["user_id"]
    );
    //console.log('/horario.php?codigo=' + dadosEmpresa[0]['user_id']);
    if (parseInt(response.data[0].difference) > 2) {
      return false;
    }

    if (parseInt(response.data[0].status_loja) === 0) {
      GravaLocal("status_loja", false);

      return false;
    }

    if (parseInt(response.data[0].verificacao) === 0) {
      GravaLocal("status_loja", false);
      return false;
    } else {
    }

    GravaLocal(
      "COMUNICACAO_" + dadosEmpresa[0]["user_id"],
      response.data[0].difference
    );
    GravaLocal("status_loja", true);
    return true;
  } catch (error) {
    //console.error("Erro ao validar status da loja:", error);
    GravaLocal("status_loja", false);
    return false;
  }
}

// export function ValidaStatusLoja() {
//     var dadosEmpresa = JSON.parse(BuscaLocal('dados_empresa'));

//     // if (dadosEmpresa[0]['user_id'] == 1) {
//     //     return true;
//     // }

//     API.get('/horario.php?codigo=' + dadosEmpresa[0]['user_id']).then(
//         function (response) {
//             //console.log(response.data[0])

//             if (parseInt(response.data[0].status_loja) == 0) {
//                 //console.log('AKi 1');
//                 return false;
//             }

//             if (parseInt(response.data[0].verificacao) == 0) {
//                 //console.log('AKi 2');
//                 return false;
//             } else {
//                 // //console.log('AKi 4');
//                 if (parseInt(response.data[0].difference) > 2) {
//                     //console.log('AKi 3');
//                     return false;
//                 }

//             }

//             try {
//                 GravaLocal('COMUNICACAO_' + dadosEmpresa[0]['user_id'], response.data[0].difference);
//             } catch (error) {

//             }
//             return true;
//         }
//     );

// }

export function DescricaoAbertura() {}

export function UserId() {
  var dadosEmpresa = JSON.parse(BuscaLocal("dados_empresa"));
  try {
    return dadosEmpresa[0]["user_id"];
  } catch (error) {
    return 0;
  }
}

export function CorFonte() {
  var dadosEmpresa = JSON.parse(BuscaLocal("dados_empresa"));
  try {
    return dadosEmpresa[0]["cor_titulo_produtos"];
  } catch (error) {
    return "#ff0000";
  }
}

export function CorFundo() {
  var dadosEmpresa = JSON.parse(BuscaLocal("dados_empresa"));
  try {
    return dadosEmpresa[0]["cor_topo"];
  } catch (error) {
    return "#000000";
  }
}

export function DeliveryAtivo() {
  var dadosEmpresa = JSON.parse(BuscaLocal("dados_empresa"));
  ////console.log(dadosEmpresa[0]['confirm_delivery']);
  try {
    if (dadosEmpresa[0]["confirm_delivery"] == 0) {
      return false;
    }
    if (dadosEmpresa[0]["confirm_delivery"] == 1) {
      return "true";
    }
    return dadosEmpresa[0]["confirm_delivery"];
  } catch (error) {
    return false;
  }
}

export function VemBuscarAtivo() {
  var dadosEmpresa = JSON.parse(BuscaLocal("dados_empresa"));
  ////////console.log(dadosEmpresa[0]['confirm_balcao']);
  try {
    if (dadosEmpresa[0]["confirm_balcao"] == 0) {
      return false;
    }
    if (dadosEmpresa[0]["confirm_balcao"] == 1) {
      return "true";
    }
    return dadosEmpresa[0]["confirm_balcao"];
  } catch (error) {
    return false;
  }
}

export function VemBuscarMensagem() {
  var dadosEmpresa = JSON.parse(BuscaLocal("dados_empresa"));
  try {
    return dadosEmpresa[0]["msg_tempo_buscar"];
  } catch (error) {
    return "";
  }
}

export function DeliveryMensagem() {
  var dadosEmpresa = JSON.parse(BuscaLocal("dados_empresa"));
  try {
    return dadosEmpresa[0]["msg_tempo_delivery"];
  } catch (error) {
    return "";
  }
}

export function MinimoDelivery() {
  var dadosEmpresa = JSON.parse(BuscaLocal("dados_empresa"));
  try {
    return dadosEmpresa[0]["minimo_delivery"];
  } catch (error) {
    return 0;
  }
}

export function DescricaoMinimoPedido() {
  if (MinimoDelivery() > 0) {
    return "• Pedido mínimo " + formatoBrasileiro.format(MinimoDelivery());
  } else {
    return "• Faça já seu pedido 😍";
  }
}

export function MensalidadePaga() {
  var dadosEmpresa = JSON.parse(BuscaLocal("dados_empresa"));
  // ////console.log(dadosEmpresa[0][67])
  return validarDataMensalidade(dadosEmpresa[0][67]);
}

function verificaHoraMaiorQueAtual(hora) {
  // Obtemos a hora atual do sistema
  const agora = new Date();

  // Criamos um novo objeto Date com a hora especificada na string
  const horaAlvo = new Date(`2000-01-01T${hora}:00`);

  // Comparamos as horas usando o método getTime() de cada objeto Date
  if (horaAlvo.getTime() > agora.getTime()) {
    return true; // hora é maior do que a hora atual
  } else {
    return false; // hora é menor ou igual à hora atual
  }
}

export function HorarioAberto(dia) {
  var dadosEmpresa = JSON.parse(BuscaLocal("dados_empresa"));

  var status = false;
  var horaIniManha = dadosEmpresa[0][dia + "_manha_de"];
  var horaFimManha = dadosEmpresa[0][dia + "_manha_ate"];
  var horaIniTarde = dadosEmpresa[0][dia + "_tarde_de"];
  var horaFimTarde = dadosEmpresa[0][dia + "_tarde_ate"];
  // //console.log(dadosEmpresa[0]['config_' + dia] == 'false')
  if (dadosEmpresa[0]["config_" + dia] != "false") {
    if (horaIniManha == horaIniTarde) {
      if (
        verificaHoraMaiorQueAtual(horaIniManha) ||
        obterDiaDaSemana() == dia
      ) {
        return "Fecha  as " + horaFimManha;
      } else {
        return horaIniManha + " as " + horaFimManha;
      }
    } else {
      return (
        "Manhã " +
        horaIniManha +
        "-" +
        horaFimManha +
        " Atarde " +
        horaIniTarde +
        "-" +
        horaFimTarde
      );
    }
  } else {
    return "Fechado";
  }
}

export function NumeroWhatsapp() {
  var dadosEmpresa = JSON.parse(BuscaLocal("dados_empresa"));
  return dadosEmpresa[0]["telefone_wpp"] || dadosEmpresa[0]["telefone_empresa"];
}

function obterNomeUsuarioInstagram(link) {
  const regex = /^https?:\/\/(?:www\.)?instagram\.com\/([A-Za-z0-9_\.]+)/;
  const resultado = regex.exec(link);
  if (resultado) {
    return `@${resultado[1]}`;
  } else {
    return null;
  }
}

function obterNomeUsuarioFacebook(link) {
  const regex = /^https?:\/\/(?:www\.)?facebook\.com\/([A-Za-z0-9_\.]+)/;
  const resultado = regex.exec(link);
  if (resultado) {
    return `@${resultado[1]}`;
  } else {
    return null;
  }
}

export function ArrobaInsta() {
  var dadosEmpresa = JSON.parse(BuscaLocal("dados_empresa"));
  return obterNomeUsuarioInstagram(dadosEmpresa[0]["instagram_empresa"]);
}

export function LinkFacebook() {
  var dadosEmpresa = JSON.parse(BuscaLocal("dados_empresa"));
  return obterNomeUsuarioFacebook(dadosEmpresa[0]["instagram_empresa"]);
}
