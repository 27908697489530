import { ApagaLocal, GravaLocal } from "../../conexao/localstorage";
import { BuscaLocal } from "../../conexao/localstorageBusca";

export function LimpaCache() {
  GravaLocal("categoria_selecionada", 0);
  GravaLocal("produto_selecionada", 0);
  GravaLocal("delivery_vembuscar", 0);
  //A principio quando concluir o pedido pegar os dados e jogar para outra variavel
  ApagaLocal("pizza_tipo_valor");
  ApagaLocal("pizza_quantidade");

  ApagaLocal("soma_sabores_selecionado");
  ApagaLocal("delivery_vembuscar");
  ApagaLocal("quantidade_sabor_selecionado");
  ApagaLocal("produto_selecionado_valor");
  ApagaLocal("produtos_selecionados");
  ApagaLocal("produto_selecionado_dados");
  ApagaLocal("soma_sabores");
  ApagaLocal("produto_selecionada");
  ApagaLocal("CUPOM_DESCONTO");

  ApagaLocal("CUPOM");
  ApagaLocal("CupomDescontoV_DESCONTO");
}
