import { Cabecalho } from "../cabecalho/cabecalho";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { API } from "../../conexao/api";
import {
  PrimeiraLetraMaiuscula,
  formatarNumeroCelular,
} from "../util/primeiraLetraMaiuscula";
import { formatoBrasileiro } from "../util/formatacaoMoeda";
import "./resumo.css";
import { FotoProduto } from "../util/imagemProduto";
import { ImagemProduto } from "../imagem/imagemProduto";
import { Notificacao } from "../util/notificacao";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { BsBagDash, BsBagPlus } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Rating } from "primereact/rating";
import ReactStars from "react-rating-stars-component";
import { CorFonte, PixMercvadoPago } from "../util/validaAbertura";
import { FaGift } from "react-icons/fa";

var PedidoAceito = {
  status: "Aceito",
  sub: "Seu pedido foi recebido, falta apenas o restaurante aceitar o seu pedido.",
  rgb: "rgb(250, 84, 29)",
  font: "#fff",
  icon: (
    <img
      src="https://img.icons8.com/external-obvious-line-kerismaker/16/null/external-bell-hotel-line-obvious-line-kerismaker.png"
      style={{
        marginTop: "12px",
        marginLeft: "12px",
        height: "16px",
        width: "16px",
        color: "black",
      }}
    ></img>
  ),
};

var PedidoProducao = {
  status: "Em Produção",
  sub: "Seu pedido já está na cozinha sendo preparado.",
  rgb: "rgb(91, 83, 247)",
  font: "#fff",
  icon: (
    <img
      src="https://img.icons8.com/windows/16/null/jog-forward.png"
      style={{
        marginTop: "12px",
        marginLeft: "12px",
        height: "16px",
        width: "16px",
        color: "black",
      }}
    ></img>
  ),
};

var PedidoPronto = {
  status: "Pronto",
  sub: "Seu pedido já foi finalizado pela cozinha.",
  rgb: "rgb(30, 140, 17)",
  font: "#fff",
  icon: (
    <img
      src="https://img.icons8.com/glyph-neue/16/null/checkmark.png"
      style={{
        marginTop: "12px",
        marginLeft: "12px",
        height: "16px",
        width: "16px",
        color: "black",
      }}
    ></img>
  ),
};

var PedidoDisponivelRetirada = {
  status: "Disponível Para Retirada",
  sub: "Seu pedido já se encontra no nosso balção para fazer sua retirada.",
  rgb: "rgb(33, 150, 243)",
  font: "#fff",
  icon: (
    <img
      src="https://img.icons8.com/ios/16/null/address--v1.png"
      style={{
        marginTop: "12px",
        marginLeft: "12px",
        height: "16px",
        width: "16px",
        color: "black",
      }}
    ></img>
  ),
};

var PedidoSaiuEntrega = {
  status: "Saiu Para Entrega",
  sub: "Seu pedido já esta indo ate você.",
  rgb: "rgb(33, 150, 243)",
  font: "#fff",
  icon: (
    <img
      src="https://img.icons8.com/wired/16/null/deliver-food.png"
      style={{
        marginTop: "12px",
        marginLeft: "12px",
        height: "16px",
        width: "16px",
        color: "black",
      }}
    ></img>
  ),
};

var PedidoFinalizado = {
  status: "Finalizado",
  sub: "Seu pedido já foi finalizado, agradeçemos pela preferência.",
  rgb: "rgb(20, 97, 12)",
  font: "#fff",
  icon: (
    <img
      src="https://img.icons8.com/glyph-neue/16/null/checkmark.png"
      style={{
        marginTop: "12px",
        marginLeft: "12px",
        height: "16px",
        width: "16px",
        color: "black",
      }}
    ></img>
  ),
};

var PedidoCancelado = {
  status: "Cancelado",
  sub: "Seu pedido foi cancelado, lamentamos pelo ocorrido.",
  rgb: "rgb(200, 11, 11)",
  font: "#fff",
  icon: (
    <img
      src="https://img.icons8.com/ios/50/null/delete-sign--v1.png"
      style={{
        marginTop: "12px",
        marginLeft: "12px",
        height: "16px",
        width: "16px",
        color: "black",
      }}
    ></img>
  ),
};

var AguardandoPagamento = {
  status: "Aguardando Pagamento",
  sub: "Você escolheu o metodo de pagamento via PIX, você deve escanear o QRCod abaixo ou cópiar a linha digitável e pagar no seu banco, assim que ser efetuado o pagamento seu pedido sera liberado. ",
  rgb: "rgb(41, 187, 172)",
  font: "#fff",
  icon: (
    <i
      class="fa-brands fa-pix"
      style={{
        marginTop: "12px",
        marginLeft: "12px",
        height: "16px",
        width: "16px",
        color: "black",
      }}
    />
  ),
  // icon: <FontAwesomeIcon icon={"face-eyes-xmarks"} />
  // icon: <FontAwesomeIcon icon="fa-regular fa-xmark" style={{color: "#ffffff",}} />
};

var PedidoPixPagho = {
  status: "PIX",
  sub: "PIX pago com sucesso",
  rgb: "rgb(41, 187, 172)",
  font: "#fff",
  icon: (
    <i
      class="fa-brands fa-pix"
      style={{
        marginTop: "12px",
        marginLeft: "12px",
        height: "16px",
        width: "16px",
        color: "black",
      }}
    />
  ),
  // icon: <FontAwesomeIcon icon={"face-eyes-xmarks"} />
  // icon: <FontAwesomeIcon icon="fa-regular fa-xmark" style={{color: "#ffffff",}} />
};

var PedidoAguardando = {
  status: "Aguardando",
  sub: "Seu pedido foi recebido, basta agora apenas ser aceito pelo restaurante!",
  rgb: "rgb(133, 7, 7)",
  font: "#fff",
  icon: (
    <img
      src="https://img.icons8.com/external-obvious-line-kerismaker/16/null/external-bell-hotel-line-obvious-line-kerismaker.png"
      style={{
        marginTop: "12px",
        marginLeft: "12px",
        height: "16px",
        width: "16px",
        color: "black",
      }}
    ></img>
  ),
};

function PedidoResgatou(pontos, data) {
  return {
    status: "Resgatou",
    sub: `Uhuu você resgatou ${pontos} pontos de fidelidade.`,
    rgb: "rgb(30, 180, 200)",
    font: "#fff",
    icon: <FaGift color={CorFonte()} size={10} />,
    data: data,
  };
}

function PedidoRecebeu(pontos, data) {
  return {
    status: "Recebeu",
    sub: `Uhuu você recebeu ${pontos} pontos de fidelidade.`,
    rgb: "rgb(30, 180, 100)",
    font: "#fff",
    icon: <FaGift color={CorFonte()} size={24} />,
    data: data,
  };
}

var events = [];

const LabelValue = ({ label, value }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ fontWeight: "bold", fontSize: "0.9rem", color: "#ccc" }}>
        {label}
      </div>
      <div style={{ fontWeight: "bold", fontSize: "1.1rem" }}>{value}</div>
    </div>
  );
};

const DivBox = (props) => {
  return (
    <div
      style={{
        border: "2px solid #cccc",
        padding: "10px",
        marginBottom: "10px",
        borderRadius: "5px",
      }}
    >
      {props.children}
    </div>
  );
};

{
  /*  */
}

var CodigoPedido = 0;
var Status = "";
var id_mp = 0;
var qrcodGerado = false;
var qrcodGeradoBase64 = "";
var carregoPrimeiravez = false;

export function ResumoPedido() {
  const { pedido } = useParams();
  const [DadosPedido, setDadosPedido] = useState([]);
  const [AvalicacaoPedido, setAvalicacaoPedido] = useState(0);

  const [DadosPedidoItens, setDadosPedidoItens] = useState([]);
  const [Delivery, setDelivery] = useState(false);
  const [opcaoStatus, setOpcaoStatus] = useState([]);
  const [QrCod, setQrCod] = useState(qrcodGeradoBase64);
  const [LinhaQrCod, setLinhaQrCod] = useState("");
  CodigoPedido = pedido;
  var BuscaPIX = false;

  const ratingChanged = (newRating) => {
    UpdateAvalicao(newRating);
  };

  const LabelAvalicao = ({ label }) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ fontWeight: "bold", fontSize: "0.9rem" }}>
          Avalie seu pedido
        </div>
        {/* <Rating value={AvalicacaoPedido} onChange={(e) => UpdateAvalicao(e.value)} cancel={false}/>    */}

        <ReactStars
          count={5}
          value={AvalicacaoPedido}
          onChange={ratingChanged}
          size={32}
          activeColor="#ffd700"
        />

        <div style={{ fontWeight: "bold", fontSize: "0.9rem", color: "#ccc" }}>
          Escolha de 1 a 5 estrelas para classificar
        </div>
      </div>
    );
  };

  function UpdateAvalicao(Valor) {
    setAvalicacaoPedido(Valor);
    API.get("avalicao_pedido.php?id=" + CodigoPedido + "&voto=" + Valor);
  }

  function formatarData(date) {
    const dia = String(date.getDate()).padStart(2, "0");
    const mes = String(date.getMonth() + 1).padStart(2, "0"); // Os meses vão de 0 (janeiro) a 11 (dezembro)
    const ano = date.getFullYear();

    const horas = String(date.getHours()).padStart(2, "0");
    const minutos = String(date.getMinutes()).padStart(2, "0");

    return `${dia}/${mes}/${ano} ${horas}h${minutos}`;
  }

  function StatusPedido() {
    API.get("status_pedido.php?codigo=" + CodigoPedido).then(function (
      response
    ) {
      events = [];
      console.log(response.data);

      for (var i = 0; i < response.data.length; i++) {
        var date = new Date(response.data[i].datahora);
        date.setUTCHours(date.getUTCHours() - 3);
        const formattedDate = formatarData(date);

        if (response.data[i].status == "Novo Pedido") {
          PedidoAguardando.data = formattedDate;
          events.push(PedidoAguardando);
        }

        if (response.data[i].status == "Cancelado") {
          PedidoCancelado.data = formattedDate;
          events.push(PedidoCancelado);
        }

        if (response.data[i].status == "Resgatou") {
          // PedidoAguardando.data = formattedDate;
          events.push(PedidoResgatou(response.data[i].pontos, formattedDate));
        }

        if (response.data[i].status == "Recebeu") {
          // PedidoAguardando.data = formattedDate;
          events.push(PedidoRecebeu(response.data[i].pontos, formattedDate));
        }

        if (response.data[i].status == "PIX GERADO") {
          AguardandoPagamento.data = formattedDate;
          events.push(AguardandoPagamento);
        }

        if (response.data[i].status == "PIX PAGO") {
          PedidoPixPagho.data = formattedDate;
          events.push(PedidoPixPagho);
          setQrCod("");
          response.data[0]["status_sistema"] = 1;
        }

        if (response.data[i].status == "Em Espera") {
          PedidoAguardando.data = formattedDate;
          events.push(PedidoAguardando);
        }
        if (response.data[i].status == "Em Produção") {
          PedidoProducao.data = formattedDate;
          events.push(PedidoProducao);
        }
        if (response.data[i].status == "Pronto") {
          PedidoPronto.data = formattedDate;
          events.push(PedidoPronto);
        }
        if (response.data[i].status == "Disponivel Para Retirada") {
          PedidoDisponivelRetirada.data = formattedDate;
          events.push(PedidoDisponivelRetirada);
        }
        if (response.data[i].status == "Saiu Para Entrega") {
          PedidoSaiuEntrega.data = formattedDate;
          events.push(PedidoSaiuEntrega);
        }
        if (response.data[i].status == "Finalizado") {
          PedidoFinalizado.data = formattedDate;
          events.push(PedidoFinalizado);
        }
      }
      setOpcaoStatus(events);
      const div = document.getElementById("status");
      div.scrollTop = 9999999999;
    });
  }

  function LoadPage() {
    ////////console.log('LOAD');
    API.get("dados_pedido.php?codigo=" + CodigoPedido).then(function (
      response
    ) {
      if (parseInt(response.data[0]["status_sistema"]) == 10) {
        ////////console.log(qrcodGeradoBase64);
        if (qrcodGeradoBase64 == "") {
          // qrcodGerado = false;
          API.get(
            "pagamento/mercadopago/gerar.php?pedido=" + response.data[0]["id"]
          ).then(function (response) {
            console.log(response.data);
            qrcodGeradoBase64 =
              "data:image/jpeg;base64," +
              response.data["transaction_data"]["qr_code_base64"];
            setQrCod(qrcodGeradoBase64);
            setLinhaQrCod(response.data["transaction_data"]["qr_code"]);
            StatusPedido();
            if (qrcodGerado == false) {
              Notificacao("", "Qrcod gerado com sucesso!");
              qrcodGerado = true;
            }
          });
        } else {
          //API.get("pagamento/mercadopago/status_pedidos.php");
          Notificacao(
            "",
            "Aguardando confirmação do PIX, apenas quando confirmado será impresso na cozinha!"
          );
        }
      }

      if (parseInt(response.data[0]["status_sistema"]) == parseInt(Status)) {
      } else {
        Status = response.data[0]["status_sistema"];

        if (response.data[0]["codigo_pedido"] === 0) {
          response.data[0]["codigo_pedido"] = "";
        }

        if (response.data[0]["status_pagamento"] == "AGUARDANDO PAGAMENTO") {
          response.data[0]["status_sistema"] = 10;
        }

        id_mp = response.data[0].id_mp;

        setDadosPedido(response.data);

        setAvalicacaoPedido(response.data[0]["voto"]);

        setDelivery(response.data[0]["opcao_delivery"] == 1);

        API.get("dados_pedido_itens.php?codigo=" + CodigoPedido).then(function (
          response
        ) {
          setDadosPedidoItens(response.data);
        });
        StatusPedido();
      }
    });
  }

  useEffect(() => {
    ////////////console.log('Componente renderizado pela primeira vez!');
    if (carregoPrimeiravez == false) {
      setInterval(() => {
        LoadPage();
        // ////////////console.log('atualiza');
      }, 30 * 1000);
      carregoPrimeiravez = true;
    }
  }, []);

  function CopiarLinhaDigitavel() {
    navigator.clipboard
      .writeText(LinhaQrCod)
      .then(() =>
        Notificacao("sucesso", "Linha digitavel copiado com sucesso!")
      );
  }

  ////////console.log(PixMercvadoPago());
  return (
    <>
      <Cabecalho onLoad={LoadPage}>
        {DadosPedido.length > 0 ? (
          <>
            <div style={{ padding: "10px" }}>
              {DadosPedido[0]["telefone"] < 9999 ? (
                <></>
              ) : (
                <>
                  {parseInt(DadosPedido[0]["codigo_pedido"]) != 0 ? (
                    <>
                      <DivBox>
                        <LabelAvalicao />
                      </DivBox>
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}

              {QrCod ? (
                <>
                  <DivBox>
                    <div
                      style={{
                        height: "300px",
                        width: "100%",
                        bacroundColor: "red",
                        marginBottom: "50px",
                      }}
                    >
                      <div
                        style={{
                          height: "150px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={QrCod}
                          style={{ height: "150px", width: "150px" }}
                        ></img>
                      </div>
                      {/* <LabelValue label={'Linha Digitavel'} value={LinhaQrCod} onClique={CopiarLinhaDigitavel}/> */}

                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                        onClick={CopiarLinhaDigitavel}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.9rem",
                            color: "#ccc",
                          }}
                        >
                          {"Linha Digitável"}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            color: "black",
                            wordWrap: "break-word",
                          }}
                        >
                          {LinhaQrCod}
                        </div>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "0.6rem",
                            color: "#ccc",
                          }}
                        >
                          Clique aqui para copiar a linha digitável!
                        </div>
                      </div>
                    </div>
                  </DivBox>
                </>
              ) : (
                <></>
              )}

              <DivBox>
                <LabelValue
                  label={"#" + DadosPedido[0]["id"]}
                  value={DadosPedido[0]["codigo_pedido"]}
                />
                {/* <LabelValue label="Data / Hora" value={converterParaDataHoraBrasil(DadosPedido[0]['data'])} /> */}
                <LabelValue label="Nome" value={DadosPedido[0]["nome"]} />
                <LabelValue
                  label="Celular"
                  value={formatarNumeroCelular(DadosPedido[0]["telefone"])}
                />

                {/* <Rating value={1} onChange={(e) => //////////console.log(e.value)} cancel={false} /> */}
                {Delivery ? (
                  <>
                    <LabelValue
                      label="Endereço"
                      value={
                        PrimeiraLetraMaiuscula(
                          DadosPedido[0]["rua"] +
                            ", " +
                            DadosPedido[0]["unidade"] +
                            " " +
                            DadosPedido[0]["bairro"] +
                            "/"
                        ) +
                        PrimeiraLetraMaiuscula(DadosPedido[0]["cidade"] + "-") +
                        DadosPedido[0]["uf"]
                      }
                    />
                    {/* "Rua João Serafim, 90 Santa Luzia/Criciúma-SC" */}
                    <LabelValue
                      label="Complemento"
                      value={PrimeiraLetraMaiuscula(
                        DadosPedido[0]["complemento"]
                      )}
                    />
                  </>
                ) : null}
              </DivBox>
              {/* <div style={{ width: '450px', marginLeft: '-200px', marginTop: '20px', marginBottom: '20px' }}> */}
              <DivBox>
                <div
                  style={{
                    height: "300px",
                    width: "100%",
                    textAlign: "left",
                    margin: "10px",
                    overflow: "auto",
                  }}
                  id="status"
                >
                  {/* <Timeline value={opcaoStatus} content={(item) => item.status} />                             */}

                  {/* //setOpcaoStatus */}

                  {opcaoStatus.map((item, index) => (
                    <VerticalTimelineElement
                      style={{ marginRight: "20px" }}
                      // className="vertical-timeline-element--work"
                      contentStyle={{ background: item.rgb, color: item.font }}
                      contentArrowStyle={{
                        borderRight: "7px solid  " + item.rgb,
                      }}
                      iconStyle={{ background: item.rgb, color: item.font }}
                      position={"left"}
                      icon={item.icon}
                      key={index}
                    >
                      <h3>{item.status}</h3>
                      <p>{item.sub}</p>
                      <p>{item.data}</p>
                    </VerticalTimelineElement>
                  ))}
                </div>
              </DivBox>

              <DivBox>
                <ul className="product-list-resumo">
                  {DadosPedidoItens.map((item, index) => (
                    <li key={index}>
                      <div
                        style={{
                          height: "100px",
                          width: "100px",
                          marginRight: "10px",
                        }}
                      >
                        <ImagemProduto
                          foto={item.img_item}
                          ifood={item.img_ifood}
                          id={item.id}
                        />
                      </div>
                      {/* <img src={FotoProduto(item.img_item, item.id)} alt="Nome do Produto" width="60" /> */}
                      <div className="product-details-resumo">
                        <h2>{PrimeiraLetraMaiuscula(item.nome_item)}</h2>
                        <p>
                          {item.ADICIONAIS.substring(
                            0,
                            item.ADICIONAIS.length - 1
                          )}
                        </p>
                        <p className="total">
                          {item.QTDE +
                            " x " +
                            formatoBrasileiro.format(item.VALOR)}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </DivBox>

              {DadosPedido[0]["telefone"] < 9999 ? (
                <></>
              ) : (
                <>
                  <DivBox>
                    <LabelValue
                      label="Meio de Pagamento"
                      value={PrimeiraLetraMaiuscula(
                        DadosPedido[0]["forma_pagamento"]
                      )}
                    />

                    {DadosPedido[0]["total"] != DadosPedido[0]["sub_total"] ? (
                      <>
                        <LabelValue
                          label="Produto"
                          value={formatoBrasileiro.format(
                            DadosPedido[0]["sub_total"]
                          )}
                        />
                      </>
                    ) : null}

                    {DadosPedido[0]["desconto"] > 0 ? (
                      <>
                        <LabelValue
                          label="Desconto"
                          value={formatoBrasileiro.format(
                            DadosPedido[0]["desconto"]
                          )}
                        />
                      </>
                    ) : null}

                    {Delivery ? (
                      <>
                        <LabelValue
                          label="Taxa"
                          value={formatoBrasileiro.format(
                            DadosPedido[0]["valor_taxa"]
                          )}
                        />
                      </>
                    ) : null}

                    {DadosPedido[0]["vl_taxa"] > 0 ? (
                      <>
                        <LabelValue
                          label="Taxa Mercado Pago"
                          value={formatoBrasileiro.format(
                            DadosPedido[0]["vl_taxa"]
                          )}
                        />
                        <LabelValue
                          label="Total"
                          value={formatoBrasileiro.format(
                            Number(DadosPedido[0]["total"]) +
                              Number(DadosPedido[0]["vl_taxa"])
                          )}
                        />
                      </>
                    ) : (
                      <LabelValue
                        label="Total"
                        value={formatoBrasileiro.format(
                          DadosPedido[0]["total"]
                        )}
                      />
                    )}
                  </DivBox>
                </>
              )}

              {/* Dados pix */}
            </div>
          </>
        ) : null}
      </Cabecalho>
    </>
  );
}
